import { NavLink } from "react-router-dom";
import BannerImg12 from "../../../Assets/Events/2022/Banner_Images/banner_10.png";
import BannerImg9 from "../../../Assets/Events/2022/Banner_Images/banner_11.png";
import BannerImg8 from "../../../Assets/Events/2022/Banner_Images/banner_12.png";
import BannerImg11 from "../../../Assets/Events/2022/Banner_Images/banner_13.png";
import BannerImg13 from "../../../Assets/Events/2022/Banner_Images/banner_14.png";
import BannerImg15 from "../../../Assets/Events/2022/Banner_Images/banner_15.png";
import BannerImg7 from "../../../Assets/Events/2022/Banner_Images/banner_5.png";
import BannerImg14 from "../../../Assets/Events/2022/Banner_Images/banner_6.webp";
import BannerImg6 from "../../../Assets/Events/2022/Banner_Images/banner_7.png";
import BannerImg10 from "../../../Assets/Events/2022/Banner_Images/banner_8.png";
import BannerImg5 from "../../../Assets/Events/2022/Banner_Images/banner_9.png";

const Events2022Banner = [
    {
        id: 1,
        bannerImage: <NavLink to={"/new-year-2022"} className="href"><img src={BannerImg5} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/new-year-2022"} className="href">Happy New Year</NavLink></h4>
    },
    {
        id: 2,
        bannerImage: <NavLink to={"/republic-day-2022"} className="href"><img src={BannerImg6} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/republic-day-2022"} className="href">Republic Day</NavLink></h4>
    },
    {
        id: 3,
        bannerImage: <NavLink to={"/womens-day-2022"} className="href"><img src={BannerImg7} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/womens-day-2022"} className="href">Women's Day</NavLink></h4>
    },
    {
        id: 4,
        bannerImage: <NavLink to={"/holi-2022"} className="href"><img src={BannerImg8} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/holi-2022"} className="href">Holi</NavLink></h4>
    },
    {
        id: 5,
        bannerImage: <NavLink to={"/kamat-bugle-rock-2022"} className="href"><img src={BannerImg9} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/kamat-bugle-rock-2022"} className="href">Kamat Bugle Rock</NavLink></h4>
    },
    {
        id: 6,
        bannerImage: <NavLink to={"/office-day-2022"} className="href"><img src={BannerImg10} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/office-day-2022"} className="href">Office Day Celebration</NavLink></h4>
    },
    {
        id: 7,
        bannerImage: <NavLink to={"/diwali-2022"} className="href"><img src={BannerImg11} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/diwali-2022"} className="href">Diwali</NavLink></h4>
    },
    {
        id: 8,
        bannerImage: <NavLink to={"/kannada-rajyotsava-2022"} className="href"><img src={BannerImg12} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/kannada-rajyotsava-2022"} className="href">Kannada Rajyotsava</NavLink></h4>
    },
    {
        id: 9,
        bannerImage: <NavLink to={"/christmas-day-2022"} className="href"><img src={BannerImg13} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/christmas-day-2022"} className="href">Christmas Day</NavLink></h4>
    },
    {
        id: 10,
        bannerImage: <NavLink to={"/wayanad-trip-2022"} className="href"><img src={BannerImg14} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/wayanad-trip-2022"} className="href">Wayanad Trip</NavLink></h4>
    },
    {
        id: 11,
        bannerImage: <NavLink to={"/birthday-celebrations-2022"} className="href"><img src={BannerImg15} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/birthday-celebrations-2022"} className="href">Birthday Celebrations</NavLink></h4>
    }
];

export default Events2022Banner;