import { NavLink } from "react-router-dom";
import BannerImg1 from "../../../Assets/Events/2021/Banner_Images/banner_1.png";
import BannerImg2 from "../../../Assets/Events/2021/Banner_Images/banner_2.png";
import BannerImg3 from "../../../Assets/Events/2021/Banner_Images/banner_3.png";
import BannerImg4 from "../../../Assets/Events/2021/Banner_Images/banner_4.png";

const Events2021Banner = [
    {
        id: 1,
        bannerImage: <NavLink to={"/independence-day-2021"} className="href"><img src={BannerImg1} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/independence-day-2021"} className="href">Independence Day</NavLink></h4>
    },
    {
        id: 2,
        bannerImage: <NavLink to={"/diwali-2021"} className="href"><img src={BannerImg4} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/diwali-2021"} className="href">Diwali</NavLink></h4>
    },
    {
        id: 3,
        bannerImage: <NavLink to={"/holi-2021"} className="href"><img src={BannerImg2} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/holi-2021"} className="href">Holi</NavLink></h4>
    },
    {
        id: 4,
        bannerImage: <NavLink to={"/evening-outing-2021"} className="href"><img src={BannerImg3} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/evening-outing-2021"} className="href">Evening Outing</NavLink></h4>
    }
];

export default Events2021Banner;