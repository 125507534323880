import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import WebContentWriters from "../../../../Assets/Careers/Openings/Website_Content_Writer.gif";
import "../CSS/CurrentOpenings.css";

const WebsiteContentWriters = () => {
    const [changeClassName, setChangeClassName] = useState(1);
    const handleShowDiv = (e) => {
        setChangeClassName(e);
    }
    return (
        <>
            <Helmet>
                <title>Holistic Learning : Website Content Writers</title>
                <meta name="description" content="Welcome to Holistic Learning, your destination for content writing career opportunities in Bangalore." />
                <link rel="canonical" href="https://holisitclearning.com/careers/website-content-writers" />
            </Helmet>
            <section className="current-openings-first-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-6 current-openings-image-div">
                            <img src={WebContentWriters} alt="" className='img-fluid' />
                        </div>
                        <div className="col-md-6 current-openings-heading-and-para-div">
                            <div className="current-openings-heading-and-para-first-inner-div">
                                <h1>WEBSITE CONTENT WRITERS</h1>
                                <p>Web Content Writer will be responsible for generating exciting and compelling stories on both digital and print sources of media and to create content for our websites and blogs focused on digital marketing, social media marketing and other services.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="current-openings-job-resume-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className={changeClassName === 1 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(1)}>
                            <h4>RESPONSIBILITY</h4>
                            {/* <h4 className='responsibility'>RESPONSIBILITY</h4> */}
                            {/* <h4 className='skills'>SKILLS REQUIRED</h4> */}
                            {/* <h4 className='qualifications'>QUALIFICATIONS</h4> */}
                        </div>
                        <div className={changeClassName === 2 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(2)}>
                            <h4>SKILLS REQUIRED</h4>
                        </div>
                        <div className={changeClassName === 3 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(3)}>
                            <h4>QUALIFICATIONS</h4>
                        </div>
                        {
                            changeClassName === 1 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-responsibility" id='reponsibility'>
                                        <ul>
                                            <li>Researching and creating valuable & engaging content on the given web pages/blog post which can help in driving traffic, and rank in SERP.</li>
                                            <li>Conducting research to collect factual information for assigned topics.</li>
                                            <li>Write compelling off-page blog articles for generating backlinks</li>
                                            <li>Conceptualizes, produces and inputs content for the websites, videos and social media.</li>
                                            <li>Developing content for blogs, articles, product descriptions, social media, and the company website.</li>
                                            <li>Working knowledge of content management systems.</li>
                                            <li>Ability to creatively translate complex concepts into easy-to-understand content appropriate for target audiences.</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                        {
                            changeClassName === 2 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-skills-required" id='skills-required'>
                                        <ul>
                                            <li>Learn the latest SEO trends and create content based on the same.</li>
                                            <li>Write quality and engaging website content.</li>
                                            <li>Write and edit accurate and concise content.</li>
                                            <li>Research new topics for blogs category for content creation.</li>
                                            <li>Writing content for social media activities as and when required.</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                        {
                            changeClassName === 3 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-qualifications" id='qualifications'>
                                        <ul>
                                            <li>Bachelor's</li>
                                            <li>Master's</li>
                                            <li>PhD</li>
                                            <li>Other Professional Degree</li>
                                        </ul>
                                        <div className="resume-subjects-div">
                                            <h5>SUBJECTS</h5>
                                            <ul>
                                                <li>H.R | Law | Arts | Nursing | Psychology | Literature | Communication | Business Management etc.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </> : ""
                        }
                    </div>
                </div>
            </section>
            <section className="current-openings-button-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 current-openings-button-div">
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdc0HD9KUuAJ2cdxBOaTb66a8YbtbY_aKF4dZ5NMF-Ah9Ptjw/viewform" className="href" target='_blank' rel='noopener noreferrer'><button className="join-the-tribe">Join The Tribe</button></a>
                            <NavLink to={"/careers"} className="href"><button className="other-openings"><span className='icon'><FaArrowLeft /></span> Other Openings</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WebsiteContentWriters;