
const PolicyForPermanentEmployee = [
    {
        id: 1,
        permanentEmployeeIcon: require("../../Assets/Careers/Policy_for_Permanent_Employee/Target.png"),
        para: "No Targets"
    },
    {
        id: 2,
        permanentEmployeeIcon: require("../../Assets/Careers/Policy_for_Permanent_Employee/Working_Week.png"),
        para: "5 Days Working Week"
    },
    {
        id: 3,
        permanentEmployeeIcon: require("../../Assets/Careers/Policy_for_Permanent_Employee/Variable_Pay.png"),
        para: "Variable Pay"
    },
    {
        id: 4,
        permanentEmployeeIcon: require("../../Assets/Careers/Policy_for_Permanent_Employee/Leaves_per_month.png"),
        para: "2 paid Leaves Per Month"
    },
    {
        id: 5,
        permanentEmployeeIcon: require("../../Assets/Careers/Policy_for_Permanent_Employee/L_Compensation.png"),
        para: "Leave Compensation"
    },
    {
        id: 6,
        permanentEmployeeIcon: require("../../Assets/Careers/Policy_for_Permanent_Employee/WFH.png"),
        para: "1 WFH Every Week"
    },
    {
        id: 7,
        permanentEmployeeIcon: require("../../Assets/Careers/Policy_for_Permanent_Employee/A_Rewards.png"),
        para: "Additional Rewards"
    },
    {
        id: 8,
        permanentEmployeeIcon: require("../../Assets/Careers/Policy_for_Permanent_Employee/Work_Culture.png"),
        para: "Flexible Work Culture"
    }
];

export default PolicyForPermanentEmployee;