
const MeetOurTeamChief = [
    {
        id: 1,
        clanImageFront: require('../../Assets/Our_Team/Colleague-Pics/Deepak-B.png'),
        clanImageBack: require('../../Assets/Our_Team/Childhood-Pics/Deepak.png'),
        clanHeading: "Deepak B",
        clanParaFront: "Founder",
        clanParaBack: "Wanted to be a Cricketer"
    },
    {
        id: 2,
        clanImageFront: require('../../Assets/Our_Team/Colleague-Pics/Arihant-B.jpeg'),
        clanImageBack: require('../../Assets/Our_Team/Childhood-Pics/Arihant.png'),
        clanHeading: "Arihant B",
        clanParaFront: "Co Founder",
        clanParaBack: "Wanted to be a Teacher"
    }
];

export default MeetOurTeamChief;