import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ITRoomSetupCSRData23 from "../../../../../../Data/Events/2023/ITRoomSetupCSRData23";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const ITRoomSetup = () => {
  return (
    <>
      <section className="carousel_slider_section">
        <div className="container carouselSliderFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  // slideShadows: true,
                }}
                // pagination={{
                //     clickable: true,
                //     dynamicBullets: true,
                // }}
                loop={true}
                autoplay={{ delay: 3000 }}
                navigation={true}
                modules={[EffectCoverflow, Navigation, Autoplay]}
                className="mySwiper"
              >
                {ITRoomSetupCSRData23.map((items) => {
                  return (
                    <SwiperSlide className="swiperSlide" key={items.id}>
                      <div className="swiper-slide-img">
                        <img
                          src={items.imageSource}
                          alt="Avatar"
                          className="nexus"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 blog-heading-and-para-div">
              <h1>
                Holistic Learning's Corporate Social Responsibility Initiative
                to Empowering Education in Rural Parts of India
              </h1>
              <p>
                At Holistic Learning, we’re driven by the mission to impart
                education to produce a transformative impact on the lives of
                individuals and communities. Upholding our commitment to
                corporate social responsibility, on February 11th, 2023,
                Holistic Learning helped G.P.H.S. School in Tumkur District
                (Karnataka) to set up a fully functional computer lab with the
                aim of helping the rural community break the technological
                barrier. We're proud to share with you our step towards
                empowering education in rural areas.
              </p>
              <h2>Bridge the Digital Divide</h2>
              <p>
                In the present digital era, access to technology is a crucial
                part of students' academics; many students without access to the
                digital world miss out on obtaining several learning
                opportunities, which in turn diminishes their potential to seek
                the job they desire. Unfortunately, India's rural region tends
                to suffer from the lack of resources, which pushes the children
                to further disadvantageous positions. Holistic Learning
                recognized this gap and took the initiative to bridge the
                digital divide at G.P.H.S. School.
              </p>
              <h2>Empowering Education Supporting Rural Communities</h2>
              <p>
                The core value of our company is driven by imparting education;
                we understand the role it plays in society. With this in mind,
                Holistic Learning fostered socio-economic development by
                unlocking opportunities for rural children. Our intention was to
                help the kids get hands-on experience with advanced learning
                opportunities; our mission in this intervention was to provide a
                dedicated computer lab for the students to explore their
                knowledge through technology-enabled resources.
              </p>
              <p>
                We believe our corporate social responsibility mission extends
                beyond our business objectives. Holistic Learning strives to
                contribute to the progress of India as a whole; we started by
                focusing on rural areas to address the educational disparities
                when compared to urban students. The students at G.P.H.S. School
                are hidden gems of India who needed a push; we believe that by
                empowering these precious minds, we could establish an equitable
                learning opportunity for them.
              </p>
              <h2>Looking Ahead with Hope</h2>
              <p>
                Join hands with Holistic Learning to sow the seed of education,
                hoping to change and foster educational opportunities for a
                brighter future for the children. This is the first step in our
                ongoing journey, and we're working on ways to explore avenues to
                support education to build a lasting positive impact on several
                lives across India.
              </p>
            </div>
            <div className="col-md-6 col-sm-6 col-12 blog-writer-div">
              <p>Written By ~ Punith R</p>
            </div>
            <div className="col-md-6 col-sm-6 col-12 blog-button-div">
              <NavLink to={"/events/more-events"} className="href">
                <button>
                  <span className="icon">
                    <FaArrowLeft />
                  </span>{" "}
                  View All Events
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ITRoomSetup;
