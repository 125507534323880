
const KamatBugleRockData22 = [
    {
        id: 1,
        imageSource: require("../../../Assets/Events/2022/Kamat-Bugle-Rock-2022/6.1.png")
    },
    {
        id: 2,
        imageSource: require("../../../Assets/Events/2022/Kamat-Bugle-Rock-2022/6.2.png")
    },
    {
        id: 3,
        imageSource: require("../../../Assets/Events/2022/Kamat-Bugle-Rock-2022/6.3.png")
    },
    {
        id: 4,
        imageSource: require("../../../Assets/Events/2022/Kamat-Bugle-Rock-2022/6.4.png")
    },
    {
        id: 5,
        imageSource: require("../../../Assets/Events/2022/Kamat-Bugle-Rock-2022/6.5.png")
    }
];

export default KamatBugleRockData22;