
const IDayData23 = [
    {
        id: 1,
        imageSource: require("../../../Assets/Events/2023/Independence_Day_2023/I_Day_1.webp")
    },
    {
        id: 2,
        imageSource: require("../../../Assets/Events/2023/Independence_Day_2023/I_Day_2.webp")
    },
    {
        id: 3,
        imageSource: require("../../../Assets/Events/2023/Independence_Day_2023/I_Day_3.webp")
    },
    {
        id: 4,
        imageSource: require("../../../Assets/Events/2023/Independence_Day_2023/I_Day_4.webp")
    },
    {
        id: 5,
        imageSource: require("../../../Assets/Events/2023/Independence_Day_2023/I_Day_5.webp")
    },
    {
        id: 6,
        imageSource: require("../../../Assets/Events/2023/Independence_Day_2023/I_Day_6.webp")
    }
];

export default IDayData23;