
const NewYearData22 = [
    {
        id: 1,
        imageSource: require("../../../Assets/Events/2022/New-Year-2022/7.1.png")
    },
    {
        id: 2,
        imageSource: require("../../../Assets/Events/2022/New-Year-2022/7.2.png")
    },
    {
        id: 3,
        imageSource: require("../../../Assets/Events/2022/New-Year-2022/7.3.png")
    },
    {
        id: 4,
        imageSource: require("../../../Assets/Events/2022/New-Year-2022/7.4.png")
    }
];

export default NewYearData22;