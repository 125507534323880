
const MainPageCSRBannerImg = [
    {
        id: 1,
        CSRBannerImg: require("../../Assets/Events/Main_Page_Images/CSR/CSR_1.webp")
    },
    {
        id: 2,
        CSRBannerImg: require("../../Assets/Events/Main_Page_Images/CSR/CSR_2.webp")
    },
    {
        id: 3,
        CSRBannerImg: require("../../Assets/Events/Main_Page_Images/CSR/CSR_3.webp")
    },
    {
        id: 4,
        CSRBannerImg: require("../../Assets/Events/Main_Page_Images/CSR/CSR_4.webp")
    }
];

export default MainPageCSRBannerImg;