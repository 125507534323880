import React, { useRef } from 'react';
import { FaAlignJustify } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import HLLogo from '../../Assets/LOGO/Header_New_Logo.png';
import "../CSS/Navbar.css";

const Navbar = () => {
    let showAndHideNavMenu = useRef("");
    const toggleNavMenu = () => {
        showAndHideNavMenu.current.classList.toggle("show-nav-un-ordered-list");
    }
    const hideNavMenu = () => {
        showAndHideNavMenu.current.classList.remove("show-nav-un-ordered-list");
    }
  return (
    <>
    <header className='main-header-of-hl sticky-top'>
        <nav className='main-navbar-of-hl'>
            <div className="nav-toggle-button">
                <span><i onClick={toggleNavMenu}><FaAlignJustify /></i></span>
            </div>
            <div className="logo-of-hl">
                <NavLink to={"/"} className="href"><img src={HLLogo} alt="Holistic Learning Bangalore" className='img-fluid' onClick={hideNavMenu} /></NavLink>
            </div>
            <div className="nav-menu-of-hl">
                <ul className="nav-un-ordered-list" ref={showAndHideNavMenu}>
                    {/* <li onClick={hideNavMenu}><NavLink to={"/know-us"} className="href">Know Us</NavLink></li> */}
                    <li onClick={hideNavMenu}><NavLink to={"/events"} className="href">Events</NavLink></li>
                    <li onClick={hideNavMenu}><NavLink to={"/our-team"} className="href">Our Team</NavLink></li>
                    <li onClick={hideNavMenu}><NavLink to={"/careers"} className="href">Careers</NavLink></li>
                    <li onClick={hideNavMenu}><NavLink to={"/products-and-services"} className="href">Products & Services</NavLink></li>
                    <li onClick={hideNavMenu}><NavLink to={"/musings"} className="href">Musings</NavLink></li>
                    <li onClick={hideNavMenu}><NavLink to={"/touchbase"} className="href">Touch Base</NavLink></li>
                </ul>
            </div>
        </nav>
    </header>
    </>
  )
}

export default Navbar;