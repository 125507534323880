import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import IDayData21 from "../../../../../../Data/Events/2021/IDayData21";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const IndependenceDay2021 = () => {
  return (
    <>
      <section className="carousel_slider_section">
        <div className="container carouselSliderFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  // slideShadows: true,
                }}
                // pagination={{
                //     clickable: true,
                //     dynamicBullets: true,
                // }}
                loop={true}
                autoplay={{ delay: 3000 }}
                navigation={true}
                modules={[EffectCoverflow, Navigation, Autoplay]}
                className="mySwiper"
              >
                {IDayData21.map((items) => {
                  return (
                    <SwiperSlide className="swiperSlide" key={items.id}>
                      <div className="swiper-slide-img">
                        <img
                          src={items.imageSource}
                          alt="Avatar"
                          className="nexus"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 blog-heading-and-para-div">
              <h1>Independence Day</h1>
              <p>
                Independence Day marks the establishment of a free and
                independent Indian nation at midnight on August 15th, 1947. We
                celebrate Independence Day throughout India through flag-raising
                ceremonies, singing the Indian National Anthem, drills, and
                various cultural programs.
              </p>
              <p>
                Holistic Learning office was decorated with balloons,
                specifically saffron, white, green, and navy blue, the tricolour
                and rangoli based on the theme. The day started with hoisting a
                flag and singing the Indian National Anthem. There was a session
                on the topic of freedom fighters, and Team Head, Mr. Deepak
                Baid, took the lead with his speech. Employees were dressed in
                white; lots of games were organized and ended with cutting the
                cake. with this refreshment, celebration, and joyful moments, we
                take the vibes of Freedom in mind, Faith in the words, Pride in
                our souls, and love for India alive.
              </p>
              <p>
                "Today is the day when we feel the love and patriotism for the
                country."
              </p>
            </div>
            <div className="col-md-6 blog-writer-div">
              <p>Written By ~ Priyanka R</p>
            </div>
            <div className="col-md-6 blog-button-div">
              <NavLink to={"/events/more-events"} className="href">
                <button>
                  <span className="icon">
                    <FaArrowLeft />
                  </span>{" "}
                  View All Events
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IndependenceDay2021;
