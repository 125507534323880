import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import NewYearData22 from "../../../../../../Data/Events/2022/NewYearData22";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const NewYear2022 = () => {
  return (
    <>
      <section className="carousel_slider_section">
        <div className="container carouselSliderFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  // slideShadows: true,
                }}
                // pagination={{
                //     clickable: true,
                //     dynamicBullets: true,
                // }}
                loop={true}
                autoplay={{ delay: 3000 }}
                navigation={true}
                modules={[EffectCoverflow, Navigation, Autoplay]}
                className="mySwiper"
              >
                {NewYearData22.map((items) => {
                  return (
                    <SwiperSlide className="swiperSlide" key={items.id}>
                      <div className="swiper-slide-img">
                        <img
                          src={items.imageSource}
                          alt="Avatar"
                          className="nexus"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 blog-heading-and-para-div">
              <h1>New Year 2022</h1>
              <p>
                A New Year brings new opportunities, ideas, thoughts, and
                learning experiences, bringing happiness and growth. This is the
                most important aspect that Holistic learning anticipates and
                wishes to achieve in every new year. At this New Year's event,
                the team of holistic learning plans and executes to make the
                best out of the day to be memorable and enjoyable. This day is
                celebrated with fun activities, sharing one another's New Year
                resolutions, sharing love with delicacies, and lots of fun
                throughout the day. On the previous day of the New Year, every
                team member contributes their creativity and decorates the
                entire office with lights, balloons, and making rangolis putting
                forward new hopes and aspirations.
              </p>
              <p>
                The New Year begins with a short opening speech by the team
                head, Mr. Deepak Baid. following this, there is a cake cutting,
                and then the fun part begins. There are lots of games where
                every team member takes part and makes the day more special.
                Nevertheless, it ends with positive talks, discussion, and
                definitely mouth-watering delicacies. All these vibes make the
                day the most exhilarating and joyous one that ensures the team
                is focused and be as happy and positive as they are for the rest
                of the year in the growth of the organization and in their
                personal life as well.
              </p>
            </div>
            <div className="col-md-6 blog-writer-div">
              <p>Written By ~ Swagata L B</p>
            </div>
            <div className="col-md-6 blog-button-div">
              <NavLink to={"/events/more-events"} className="href">
                <button>
                  <span className="icon">
                    <FaArrowLeft />
                  </span>{" "}
                  View All Events
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewYear2022;
