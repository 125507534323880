
const IDayData21 = [
    {
        id: 1,
        imageSource: require("../../../Assets/Events/2021/Independence-Day-2021/5.1.png")
    },
    {
        id: 2,
        imageSource: require("../../../Assets/Events/2021/Independence-Day-2021/5.2.png")
    },
    {
        id: 3,
        imageSource: require("../../../Assets/Events/2021/Independence-Day-2021/5.3.png")
    },
    {
        id: 4,
        imageSource: require("../../../Assets/Events/2021/Independence-Day-2021/5.4.png")
    }
];

export default IDayData21;