import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RDayImage1 from "../../../../../../Assets/Events/2021/Holi-2021/3.1.webp";
import RDayImage2 from "../../../../../../Assets/Events/2021/Holi-2021/3.2.png";
import RDayImage3 from "../../../../../../Assets/Events/2021/Holi-2021/3.3.png";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const Holi2021 = () => {
  return (
    <>
      <section className="carousel_slider_section">
        <div className="container carouselSliderFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  // slideShadows: true,
                }}
                // pagination={{
                //     clickable: true,
                //     dynamicBullets: true,
                // }}
                loop={true}
                autoplay={{ delay: 3000 }}
                navigation={true}
                modules={[EffectCoverflow, Navigation, Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage1} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage2} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage3} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage1} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage2} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage3} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 blog-heading-and-para-div">
              <h1>Holi</h1>
              <p>
                Holi is a festival that is symbolic of spring and vibrant
                colors. It even symbolizes triumph and joy. Each color exhales
                the beauty of life, and it even teaches us how to embrace life
                when it shows up in different colors. It is one of the most
                prominent festivals in Indian culture. Holistic learning felt
                the need to celebrate this festival with fun, exuberance, and
                joy. The two hours of splattering colors made each one forget
                about the stress and the pending deadlines; what mattered was to
                enjoy the vibrant colors.
              </p>
              <p>
                The festival of Holi transformed the office into a color
                playground, and each color was tossed around everyone, making
                them looking yearning for more. At the same time, from the
                office boy to the founder each employee looked priceless by
                flashing their colors in the premise. It revealed there is a
                child in each of us, and this child wants to have uninterrupted
                fun with an embodiment of genuine laughter.
              </p>
              <p>
                The festival concluded with Thandai (an Indian drink decorated
                with almonds and pistachio that provides instant energy) and
                Rabadi Jalebi. There is nothing more one can ask for with
                sweets, color, and friends around in a working atmosphere, where
                diversity is the soul. Holistic learning never inhibits
                enumerating the diverse culture in action through festivals,
                culture, and work.
              </p>
            </div>
            <div className="col-md-6 blog-writer-div">
              <p>Written By ~ Shruti C</p>
            </div>
            <div className="col-md-6 blog-button-div">
              <NavLink to={"/events/more-events"} className="href">
                <button>
                  <span className="icon">
                    <FaArrowLeft />
                  </span>{" "}
                  View All Events
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Holi2021;
