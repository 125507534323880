import React from 'react';
import { Helmet } from 'react-helmet';
import LetsConnect from "../../Assets/Touchbase/Address.gif";
import Email from "../../Assets/Touchbase/Locate_us_at/Email.png";
import Landline from "../../Assets/Touchbase/Locate_us_at/Landline.png";
import Location from "../../Assets/Touchbase/Locate_us_at/Location.png";
import Mobile from "../../Assets/Touchbase/Locate_us_at/Mobile.png";
import JobProfileData from '../../Data/TouchBase/JobProfileData';
import "../CSS/Touchbase.css";

const Touchbase = () => {
  return (
    <>
      <Helmet>
        <title>Holistic Learning : Touchbase</title>
        {/* <meta name="description" content="We at Holistic Learning offer students help with their assignments, homework and online courses. We assure grade A or B or money back."
        /> */}
        <link rel="canonical" href="https://holisitclearning.com/touchbase" />
      </Helmet>
      <section className="get-in-touch-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 get-in-touch-heading-div">
              <h2>Get In <span>TOUCH</span></h2>
            </div>
            <form name="contact-form" method="post" enctype="multipart/form-data" netlify-honeypot="bot-field" data-netlify-recaptcha="true" data-netlify="true" onSubmit="submit">
              <input type="hidden" name="form-name" value="contact-form" />
              <div className="row justify-content-center">
                <div className="col-md-6 mb-3 get-in-touch-form-div">
                  <label htmlFor="name" className="form-label">Name</label>
                  <input type="text" className="form-control" id="name" name='name' />
                </div>
                <div className="col-md-6 mb-3 get-in-touch-form-div">
                  <label htmlFor="email" className="form-label">Email</label>
                  <input type="email" className="form-control" id="email" name='email' />
                </div>
                <div className="col-md-6 mb-3 get-in-touch-form-div">
                  <label htmlFor="jobProfile" className="form-label">Job Profile</label>
                  <select className="form-select" aria-label="Default select example" name='jobProfile' id='jobProfile'>
                    <option defaultValue></option>
                    {JobProfileData.map((item, index) => (
                      <option key={index} id={item.id} value={item.value}>
                        {item.profile}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 mb-3 get-in-touch-form-div">
                  <label htmlFor="resume" className="form-label">Resume</label>
                  <input className="form-control" type="file" id="resume" name='resume' />
                </div>
                <div className="col-md-12 mb-3 get-in-touch-form-div">
                  <label htmlFor="message" className="form-label">Message</label>
                  <textarea className="form-control" id="message" name='message' rows="3"></textarea>
                </div>
                <div className="col-md-12 mb-3 get-in-touch-button-div">
                  <button type="submit">SUBMIT</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section className="join-the-tribe-lets-connect-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-6 join-the-tribe-lets-connect-image-div">
              <img src={LetsConnect} alt="" className='img-fluid' />
            </div>
            <div className="col-md-6 join-the-tribe-lets-connect-heading-and-para-div">
              <div className="join-the-tribe-lets-connect-heading-and-para-first-inner-div">
                <h2>Lets <span>CONNECT</span></h2>
                <div className="lets-connect-div">
                  <p className="email"><span className='email-icon'><img src={Email} alt="" /></span><span className='email-para'>hr@holisticlearningllc.com</span></p>
                  <p className="mobile"><span className='mobile-icon'><img src={Mobile} alt="" /></span><span className='mobile-para'>+91 72594 10987</span></p>
                  <p className="landline"><span className='landline-icon'><img src={Landline} alt="" /></span><span className='landline-para'>080 2223 3221</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="visit-us-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 visit-us-map-heading-div">
              <h2><span>VISIT</span> Us</h2>
              <p><span className="location-icon"><img src={Location} alt="" /></span><span className="location-para">#40, 1st Floor,10th Cross Road,Wilson Garden, Bengaluru,Karnataka - 560 027</span></p>
            </div>
            <div className="col-md-12 visit-us-map-div">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.3859674363152!2d77.59397357392342!3d12.947136315416481!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1581e74e1099%3A0x120a838a65411598!2sHolistic%20Learning!5e0!3m2!1sen!2sin!4v1701403655494!5m2!1sen!2sin" width="100%" height="500" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Holistic Learning, Bangalore'></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Touchbase;