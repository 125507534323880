import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RDayImage1 from "../../../../../../Assets/Events/2022/CSR/AbhayaAshrama23/1.webp";
import RDayImage2 from "../../../../../../Assets/Events/2022/CSR/AbhayaAshrama23/2.webp";
import RDayImage3 from "../../../../../../Assets/Events/2022/CSR/AbhayaAshrama23/3.webp";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const AbhayaAshrama2022 = () => {
  return (
    <>
      <section className="carousel_slider_section">
        <div className="container carouselSliderFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  // slideShadows: true,
                }}
                // pagination={{
                //     clickable: true,
                //     dynamicBullets: true,
                // }}
                loop={true}
                autoplay={{ delay: 3000 }}
                navigation={true}
                modules={[EffectCoverflow, Navigation, Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage1} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage2} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage3} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage1} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage2} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage3} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 blog-heading-and-para-div">
              <h1>Solidarity with Children at Abhya Ashrama</h1>
              <p>
                In a society, where we, as a corporate, have to prioritize
                profit and engage in fierce competition, it was truly inspiring
                for the tribe to remind the company about its social
                responsibilities and actively works to improve the community.
                At, Holistic Learning, we believe in making an impact beyond
                mere words. We are proud to share our recent Corporate Social
                Responsibility (CSR) project, which exemplifies our commitment
                to creating a positive societal effect.
              </p>
              <h2>Extending a Helping Hand:</h2>
              <p>
                We were greatly impacted as a team when we learned about the
                problems that the children at Abhya Ashram confront. We felt
                motivated to make a genuine difference in their lives.
                Recognizing that education is the path to empowerment, we took
                the initiative to provide the necessary resources. We want to
                improve their learning experience and stimulate creativity by
                providing essential equipment such as pens, notebooks, and
                paper.
              </p>
              <h2>Nourishing the Future:</h2>
              <p>
                We understand that a hungry stomach has no ears! Acknowledging
                the significance of nourishment in a child's overall
                development, we determined to address the nutritional needs of
                the children at Abhya Ashram. Through our contributions to
                groceries, including essentials like oil, dhal, rice, and
                napkins, we aimed to ensure that these young minds were
                well-nourished and had the energy to pursue their dreams. By
                alleviating the burden of sourcing daily sustenance, we enabled
                the children to focus on their studies and explore their innate
                talents.
              </p>
              <h2>Touching Lives:</h2>
              <p>
                We firmly believe that even the simplest items can profoundly
                impact a child's life. With this understanding, we provided
                napkins to the children at Abhya Ashram. We recognize that these
                seemingly insignificant items contribute to the children's sense
                of dignity, comfort, and hygiene. By extending our support to
                include napkins, we demonstrate our commitment to the holistic
                well-being of these children. We aim to address their unique
                needs and challenges.
              </p>
              <h2>A Ripple Effect of Transformation:</h2>
              <p>
                The impact of Holistic Learning's corporate social
                responsibility initiative extends far beyond the walls of Abhya
                Ashram. We hope our compassionate activities motivate
                individuals to stand up and contribute to society's well-being.
                We are convinced that every entity, no matter how large or
                small, can help create a more equal and inclusive world. We can
                collectively bring transformative change by embracing
                responsibility and leveraging our resources.
              </p>
              <p>
                In a world where corporate social responsibility can often feel
                like a mere formality, Holistic Learning's initiative is a
                genuine testament to care and commitment. Providing groceries to
                Abhya Ashram nurtures young souls, fosters growth, and seeds
                compassion. We are creating a brighter future for these children
                through a comprehensive approach to education and community
                involvement. Our little deeds remind us of our enormous power to
                influence positively. Let us collaborate to empower poor
                children, realizing that even the simplest gestures can
                significantly impact their life.
              </p>
            </div>
            <div className="col-md-6 blog-writer-div">
              <p>Written By ~ Keerthana V</p>
            </div>
            <div className="col-md-6 blog-button-div">
              <NavLink to={"/events/more-events"} className="href">
                <button>
                  <span className="icon">
                    <FaArrowLeft />
                  </span>{" "}
                  View All Events
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AbhayaAshrama2022;
