import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import JoinTheTribe from "../../Assets/Careers/Interview.gif";
import FreelancingOpportunities from '../../Data/Careers/FreelancingOpportunities';
import PolicyForPermanentEmployee from '../../Data/Careers/PolicyForPermanentEmployee';
import "../CSS/Careers.css";

const Careers = () => {
  const [newClassName, setNewClassName] = useState(null);
  const addNewClassName = (e) => {
    setNewClassName(e)
  }
  useEffect(() => {
    addNewClassName();
  }, [])



  return (
    <>
      <Helmet>
        <title>Holistic Learning : Content Writer Jobs in Bangalore</title>
        <meta name="description" content="Welcome to Holistic Learning, your destination for content writing career opportunities in Bangalore." />
        <link rel="canonical" href="https://holisitclearning.com/careers" />
      </Helmet>
      <section className="join-the-tribe-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6 join-the-tribe-heading-and-para-div">
              <div className="join-the-tribe-heading-and-para-first-inner-div">
                <h2><span>JOIN</span> The Tribe</h2>
                <p>Become a tribesman or tribeswoman and grow along with the tribe. The requirements are low but expectations are sky high. Rules are negligible but there is eagle-eye focus on performance and productivity. A fun and easy work culture with a high performance team and smart working environment.</p>
              </div>
            </div>
            <div className="col-lg-6 join-the-tribe-image-div">
              <img src={JoinTheTribe} alt="" className='img-fluid' />
            </div>
          </div>
        </div>
      </section>
      <section className="policy-for-permanent-employee-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 policy-for-permanent-employee-heading-div">
              <h2><span>POLICY</span> For Permanent Employee</h2>
            </div>
            {
              PolicyForPermanentEmployee.map((items) => {
                return (
                  <div className="col-lg-3 col-md-4 col-sm-6 col-6 policy-for-permanent-employee-card-div" key={items.id}>
                    <div className="policy-for-permanent-employee-card-first-inner-div">
                      <img src={items.permanentEmployeeIcon} alt="" className='img-fluid' />
                      <p>{items.para}</p>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </section>
      <section className="current-openings-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 current-openings-heading-div">
              <h2><span>CURRENT</span> Openings</h2>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 current-openings-card-div">
              <NavLink to={"/careers/general-content-writers"} className="href">
                <div className={newClassName === 1 ? `current-openings-card-first-inner-div-with-hover-effect` : `current-openings-card-first-inner-div`} onMouseEnter={() => addNewClassName(1)} onMouseLeave={addNewClassName}>
                  <h4>GENERAL CONTENT WRITERS</h4>
                  <p className="qualification">
                    <span>Qualification - </span>Bachelors, Masters and other Professional Degrees.
                  </p>
                  <p className="subjects">
                    <span>Subjects - </span>H.R | Law | Arts | Nursing | Psychology | Literature | Communication | Business Management etc.
                  </p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 current-openings-card-div">
              <NavLink to={"/careers/technical-content-writers"} className="href">
                <div className={newClassName === 2 ? `current-openings-card-first-inner-div-with-hover-effect` : `current-openings-card-first-inner-div`} onMouseEnter={() => addNewClassName(2)} onMouseLeave={addNewClassName}>
                  <h4>TECHNICAL CONTENT WRITERS</h4>
                  <p className="qualification">
                    <span>Qualification - </span>Bachelors, Masters and other Professional Degrees.
                  </p>
                  <p className="subjects">
                    <span>Subjects - </span>Maths | Accounts | Finance | Science | Statistics | Economics | Engineering etc.
                  </p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 current-openings-card-div">
              <NavLink to={"/careers/website-content-writers"} className="href">
                <div className={newClassName === 3 ? `current-openings-card-first-inner-div-with-hover-effect` : `current-openings-card-first-inner-div`} onMouseEnter={() => addNewClassName(3)} onMouseLeave={addNewClassName}>
                  <h4>WEBSITE CONTENT WRITERS</h4>
                  <p className="qualification">
                    <span>Qualification - </span>Bachelors, Masters and other Professional Degrees.
                  </p>
                  <p className="subjects">
                    <span>Subjects - </span>Fluency in English | Writing & Research | MS Word | MS Excel | MS Powerpoint | Good Vocabulary & Presentation etc.
                  </p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 current-openings-card-div">
              <NavLink to={"/careers/digital-marketing-executives"} className="href">
                <div className={newClassName === 4 ? `current-openings-card-first-inner-div-with-hover-effect` : `current-openings-card-first-inner-div`} onMouseEnter={() => addNewClassName(4)} onMouseLeave={addNewClassName}>
                  <h4>DIGITAL MARKETING EXECUTIVES</h4>
                  <p className="qualification">
                    <span>Qualification - </span>Bachelors, Masters and other Professional Degrees.
                  </p>
                  <p className="subjects">
                    <span>Subjects - </span>Content Writing | Link Building | Backlinks | PPC, CPC | SEO | Google Adwords | Web Analysis | Competitor Analysis
                  </p>
                </div>
              </NavLink>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 current-openings-card-div">
              <div className={newClassName === 5 ? `current-openings-card-first-inner-div-with-hover-effect` : `current-openings-card-first-inner-div`} onMouseEnter={() => addNewClassName(5)} onMouseLeave={addNewClassName}>
                <h4>WEB DEVELOPERS</h4>
                <div className={newClassName === 5 ? `developers-role-on-current-openings-page-with-hover-effect` : `developers-role-on-current-openings-page`}>
                  <p className="frontend-developers"><NavLink to={"/careers/front-end-developers"} className="href">Front-end Developers</NavLink></p>
                  <p className="backend-developers"><NavLink to={"/careers/back-end-developers"} className="href">Back-end Developers</NavLink></p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 current-openings-card-div">
              <NavLink to={"/careers/ui-ux-designer"} className="href">
                <div className={newClassName === 6 ? `current-openings-card-first-inner-div-with-hover-effect` : `current-openings-card-first-inner-div`} onMouseEnter={() => addNewClassName(6)} onMouseLeave={addNewClassName}>
                  <h4>UI/UX DESIGNERS</h4>
                  <p className="qualification">
                    <span>Qualification - </span>Bachelors, Masters and other Professional Degrees.
                  </p>
                  <p className="subjects">
                    <span>Subjects - </span>Adobe Illustrator | Photoshop / Figma etc. | Wireframe Tools | Understanding the standards of Fonts, Colors & Images | Time Management
                  </p>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <section className="freelancing-opportunities-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 freelancing-opportunities-heading-div">
              <h2><span>FREELANCING</span> Opportunities</h2>
            </div>
            {
              FreelancingOpportunities.map((items) => {
                return (
                  <div className="col-lg-3 col-md-4 col-sm-6 col-6 freelancing-opportunities-card-div" key={items.id}>
                    <div className="freelancing-opportunities-card-first-inner-div">
                      <img src={items.permanentEmployeeIcon} alt="" className='img-fluid' />
                      <p>{items.para}</p>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </section>
    </>
  )
}

export default Careers;