import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import KannadaDayData22 from '../../../../../../Data/Events/2022/KannadaDayData22';
import "../../CSSBlogs/MainBlogsCSSFile.css";

const KannadaDay2022 = () => {
  return (
    <>
    <section className="carousel_slider_section">
        <div className="container carouselSliderFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  // slideShadows: true,
                }}
                // pagination={{
                //     clickable: true,
                //     dynamicBullets: true,
                // }}
                loop={true}
                autoplay={{ delay: 3000 }}
                navigation={true}
                modules={[EffectCoverflow, Navigation, Autoplay]}
                className="mySwiper"
              >
                {KannadaDayData22.map((items) => {
                  return (
                    <SwiperSlide className="swiperSlide" key={items.id}>
                      <div className="swiper-slide-img">
                        <img
                          src={items.imageSource}
                          alt="Avatar"
                          className="nexus"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-md-12 blog-heading-and-para-div">
                    <h1>Kannada Rajyotsava Celebration #2022</h1>
                    <p>Kannada Rajyotsava is celebrated every year on November 1st; it was the first time a Kannada event was organized in Holistic Learning. Meghana and Priyanka systematized it. The program started with the lighting up of lamps and Puja, which filled the environment with positivity, vitality, and joy, along with sweets distribution.</p>
                    <p>The dress code and office decoration coordinated with the flag color red and yellow. It enriched the tradition of Karnataka and added more energy to the office. The warm welcome to the event started with Kannada Nada Geethe (Karnataka Anthem), "Jaya Bharata Jananiya Tanujate," promoting diversity in the office.</p>
                    <p>Everyone is talented in their own way. To showcase, we organized games, and the team participated with enthusiasm. A quiz about Karnataka, the center of attraction among all games, was conducted, and the two highest scorers won interesting prizes. A small tribute for our soldiers by lighting candles and a speech session was planned about Karnataka's food, culture, places, and freedom fighters. All team members presented their thoughts which made us proud to be in Karnataka.</p>
                    <p>Lunch? Yes!! It was Potluck; it was exciting to taste food that was prepared by themselves and was served on banana leaves, our tradition of having food. Finally, the day ended with evening snacks, one's favorite pani puri!</p>
                </div>
                <div className="col-md-6 blog-writer-div">
                    <p>Written By ~ Meghashree M V</p>
                </div>
                <div className="col-md-6 blog-button-div">
                    <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default KannadaDay2022;