import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import BDeveloper from "../../../../Assets/Careers/Openings/Back-end_Developer.gif";
import "../CSS/CurrentOpenings.css";

const BackendDevelopers = () => {
    const [changeClassName, setChangeClassName] = useState(1);
    const handleShowDiv = (e) => {
        setChangeClassName(e);
    }
    return (
        <>
            <Helmet>
                <title>Holistic Learning : Back-end Developer</title>
                <meta name="description" content="Welcome to Holistic Learning, your destination for content writing career opportunities in Bangalore." />
                <link rel="canonical" href="https://holisitclearning.com/careers/back-end-developers" />
            </Helmet>
            <section className="current-openings-first-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-6 current-openings-image-div">
                            <img src={BDeveloper} alt="" className='img-fluid' />
                        </div>
                        <div className="col-md-6 current-openings-heading-and-para-div">
                            <div className="current-openings-heading-and-para-first-inner-div">
                                <h1>BACK-END DEVELOPERS</h1>
                                <p>As a Back-end Developer, you will be managing the exchange of data between the server and the users and will also be responsible for the integration of the front-end elements and ensuring high performance and responsiveness to requests from the front-end.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="current-openings-job-resume-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className={changeClassName === 1 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(1)}>
                            <h4>RESPONSIBILITY</h4>
                            {/* <h4 className='responsibility'>RESPONSIBILITY</h4> */}
                            {/* <h4 className='skills'>SKILLS REQUIRED</h4> */}
                            {/* <h4 className='qualifications'>QUALIFICATIONS</h4> */}
                        </div>
                        <div className={changeClassName === 2 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(2)}>
                            <h4>SKILLS REQUIRED</h4>
                        </div>
                        <div className={changeClassName === 3 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(3)}>
                            <h4>QUALIFICATIONS</h4>
                        </div>
                        {
                            changeClassName === 1 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-responsibility" id='reponsibility'>
                                        <ul>
                                            <li>Collaborate with front-end developers.</li>
                                            <li>Conduct UI tests and optimize performance.</li>
                                            <li>Write clean code to develop functional web applications.</li>
                                            <li>Knowledgeable of front-end technologies and platforms, such as JavaScript, HTML5, and CSS3, Node.js/Python.</li>
                                            <li>Participate in the entire application life-cycle, focusing on coding and debugging.</li>
                                            <li>Good understanding of server-side CSS pre-processors.</li>
                                            <li>Capability to integrate multiple data sources and databases into one system.</li>
                                            <li>Ability to setup, administer of backups, data migration, transformation, and scripting.</li>
                                            <li>Knowledgeable of OWASP security principles.</li>
                                            <li>Understands “session management” in a server environment.</li>
                                            <li>Deployment strategies on the cloud (AWS, Google Cloud, Heroku, etc.).</li>
                                            <li>Keeping a finger on the pulse of the latest technologies for constant growth and improvement.</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                        {
                            changeClassName === 2 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-skills-required" id='skills-required'>
                                        <ul>
                                            <li>Coding (HTML/CSS3)</li>
                                            <li>Debugging</li>
                                            <li>Programming (JavaScript, PHP, ASP.NET, Ruby on Rails)</li>
                                            <li>Analytical Skills</li>
                                            <li>Web Development</li>
                                            <li>API</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                        {
                            changeClassName === 3 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-qualifications" id='qualifications'>
                                        <ul>
                                            <li>Bachelor's</li>
                                            <li>Master's</li>
                                            <li>PhD</li>
                                            <li>Other Professional Degree</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                    </div>
                </div>
            </section>
            <section className="current-openings-button-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 current-openings-button-div">
                            <NavLink to={"/touchbase"} className="href"><button className="join-the-tribe">Join The Tribe</button></NavLink>
                            <NavLink to={"/careers"} className="href"><button className="other-openings"><span className='icon'><FaArrowLeft /></span> Other Openings</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BackendDevelopers;