import React, { useState } from 'react';
import Events2021Banner from '../../../../Data/Events/2021/Events2021Banner';
import Events2022Banner from '../../../../Data/Events/2022/Events2022Banner';
import Events2023Banner from '../../../../Data/Events/2023/Events2023Banner';
import Events2024Banner from '../../../../Data/Events/2024/Events2024Banner';
import "../CSS/EventsAndCSRInnerPages.css";

const EventsInnerPages = () => {
    const [toggleClassName, setToggleClassName] = useState(1);
    const handleToggleClassName = (e) => {
        setToggleClassName(e);
    }
  return (
    <>
    <section className="events-inner-pages-button-and-image-section-start-here">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-3 col-sm-3 col-3 events-inner-pages-button-div">
            {/* <div className="events-button-first-inner-div"></div> */}
            <h4 className={toggleClassName === 1 ? `click-events-inner-pages` : `without-click-events-inner-pages`} onClick={() => handleToggleClassName(1)}>2024</h4>
            <h4 className={toggleClassName === 2 ? `click-events-inner-pages` : `without-click-events-inner-pages`} onClick={() => handleToggleClassName(2)}>2023</h4>
            <h4 className={toggleClassName === 3 ? `click-events-inner-pages` : `without-click-events-inner-pages`} onClick={() => handleToggleClassName(3)}>2022</h4>
            <h4 className={toggleClassName === 4 ? `click-events-inner-pages` : `without-click-events-inner-pages`} onClick={() => handleToggleClassName(4)}>2021</h4>
          </div>
          <div className="col-md-9 col-sm-9 col-9 events-inner-pages-image-div">
            <div className="container-fluid">
                <div className="row justify-flex-start">
                {
                        toggleClassName === 1 ?
                        <>
                        {
                        Events2024Banner.slice(0).reverse().map((items) => {
                            return(
                                <div className="col-md-6 col-sm-6 col-12 events-inner-pages-banner-images-div" key={items.id}>
                                    <div className="events-inner-pages-banner-images-first-inner-div">
                                        {items.bannerImage}
                                        {items.bannerPara}
                                    </div>
                                </div>
                            );
                        })
                    }
                        </> : ""
                    }
                    {
                        toggleClassName === 2 ?
                        <>
                        {
                        Events2023Banner.slice(0).reverse().map((items) => {
                            return(
                                <div className="col-md-6 col-sm-6 col-12 events-inner-pages-banner-images-div" key={items.id}>
                                    <div className="events-inner-pages-banner-images-first-inner-div">
                                        {items.bannerImage}
                                        {items.bannerPara}
                                    </div>
                                </div>
                            );
                        })
                    }
                        </> : ""
                    }
                    {
                        toggleClassName === 3 ?
                        <>
                        {
                        Events2022Banner.slice(0).reverse().map((items) => {
                            return(
                                <div className="col-md-6 col-sm-6 col-12 events-inner-pages-banner-images-div" key={items.id}>
                                    <div className="events-inner-pages-banner-images-first-inner-div">
                                        {items.bannerImage}
                                        {items.bannerPara}
                                    </div>
                                </div>
                            );
                        })
                    }
                        </> : ""
                    }
                    {
                        toggleClassName === 4 ?
                        <>
                        {
                        Events2021Banner.slice(0).reverse().map((items) => {
                            return(
                                <div className="col-md-6 col-sm-6 col-12 events-inner-pages-banner-images-div" key={items.id}>
                                    <div className="events-inner-pages-banner-images-first-inner-div">
                                        {items.bannerImage}
                                        {items.bannerPara}
                                    </div>
                                </div>
                            );
                        })
                    }
                        </> : ""
                    }
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default EventsInnerPages;