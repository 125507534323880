import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Diwali22 from '../../../../../../Data/Events/2022/Diwali22';
import "../../CSSBlogs/MainBlogsCSSFile.css";

const Diwali2022 = () => {
  return (
    <>
    <section className="carousel_slider_section">
        <div className="container carouselSliderFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  // slideShadows: true,
                }}
                // pagination={{
                //     clickable: true,
                //     dynamicBullets: true,
                // }}
                loop={true}
                autoplay={{ delay: 3000 }}
                navigation={true}
                modules={[EffectCoverflow, Navigation, Autoplay]}
                className="mySwiper"
              >
                {Diwali22.map((items) => {
                  return (
                    <SwiperSlide className="swiperSlide" key={items.id}>
                      <div className="swiper-slide-img">
                        <img
                          src={items.imageSource}
                          alt="Avatar"
                          className="nexus"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-md-12 blog-heading-and-para-div">
                    <h1>Diwali Celebration #2022</h1>
                    <p>"Diwali is a time to sparkle the sky with joy and light up lives with happiness!" it often reminds everyone's hearts with the "Festive of lights." It is the time for celebration and joy at Holistic Learning, over two days with various fun and festive activities that left everyone feeling "all aglow!".</p>
                    <p>On Oct 22, We kicked off the festivities by "decking the halls" with a simple and traditional way to "Light up the room" and dazzle to delight everywhere. Everyone, "Birds of a feather flock together!" joined hands to help each other. In the afternoon, we enjoyed a "feast fit for a king and queen!" with a delicious Diwali lunch that left everyone feeling "full as a tick!" complete with a wide range of Veg Briyani to show the sign of sharing and caring in Holistic learning.</p>
                    <p>After lunch, we "let our hair down" with games and activities. "Take a stab at the mystery word." The team members divided themselves into two groups. Two minds come together in a battle of wits. "Put your guessing skills to the test" One member will be given the word, and the other has to guess the word. Clues will drop, but only three chances to guess the answer. The remaining members should refrain from giving hints or guessing the answers.</p>
                    <p>The second game is Two truths and one lie. "Can you spot the fib?" Each member of both groups participated in the game—team up to uncover the truth. Opposite team members should discuss and say which statement was a lie. Can you sniff out the lie? The opposite group awards themselves a point if they guess the correct lie.</p>
                    <p>Finally, we Gather around for a game of Uno! The competition aims to be the first to eliminate all the cards. Match the cards in your hand with the discard pile by color or number. "Draw, play, and win.".</p>
                    <p>On the second day of Diwali, we started the day off with a special morning with a traditional puja ceremony, where employees could offer prayers and make offerings to the gods, done by office members, Deepak sir family members. The puja was a "solemn and meaningful" occasion that brought a sense of "peace and tranquility" to the office. And then we had a "smorgasbord" of savory and sweet snacks. After the snacks, our founders came up to "Dazzle us with a memorable Diwali gift" smartwatches for everyone.</p>
                    <p>Overall, our two days of Diwali celebrations were a "resounding success." The decorations, games, and activities brought a sense of joy and unity to the office, and the delicious food and puja ceremony added a touch of tradition to the festivities. "We Celebrate Diwali with a dazzling display of joy and positivity!" We can't wait to "do it all again" next year!</p>
                </div>
                <div className="col-md-6 blog-writer-div">
                    <p>Written By ~ Keerthana V</p>
                </div>
                <div className="col-md-6 blog-button-div">
                    <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Diwali2022;