
const KannadaDayData22 = [
    {
        id: 1,
        imageSource: require("../../../Assets/Events/2022/kannada_rajyotsava/diwas_1.png")
    },
    {
        id: 2,
        imageSource: require("../../../Assets/Events/2022/kannada_rajyotsava/diwas_2.png")
    },
    {
        id: 3,
        imageSource: require("../../../Assets/Events/2022/kannada_rajyotsava/diwas_3.png")
    },
    {
        id: 4,
        imageSource: require("../../../Assets/Events/2022/kannada_rajyotsava/diwas_4.png")
    },
    {
        id: 5,
        imageSource: require("../../../Assets/Events/2022/kannada_rajyotsava/diwas_5.png")
    },
    {
        id: 6,
        imageSource: require("../../../Assets/Events/2022/kannada_rajyotsava/diwas_6.png")
    }
];

export default KannadaDayData22;