import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import TechContentWriters from "../../../../Assets/Careers/Openings/Technical_Content_Writer.gif";
import "../CSS/CurrentOpenings.css";

const TechnicalContentWriters = () => {
    const [changeClassName, setChangeClassName] = useState(1);
    const handleShowDiv = (e) => {
        setChangeClassName(e);
    }
    return (
        <>
            <Helmet>
                <title>Holistic Learning : Technical Content Writers</title>
                <meta name="description" content="Welcome to Holistic Learning, your destination for content writing career opportunities in Bangalore." />
                <link rel="canonical" href="https://holisitclearning.com/careers/technical-content-writers" />
            </Helmet>
            <section className="current-openings-first-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-6 current-openings-image-div">
                            <img src={TechContentWriters} alt="" className='img-fluid' />
                        </div>
                        <div className="col-md-6 current-openings-heading-and-para-div">
                            <div className="current-openings-heading-and-para-first-inner-div">
                                <h1>TECHNICAL CONTENT WRITERS</h1>
                                <p>Responsible for developing relevent content in their respective field of specialization as well as in other areas gradually. gradually. Candidates should have a knack for writing and good command over the subject as per their specialization.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="current-openings-job-resume-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className={changeClassName === 1 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(1)}>
                            <h4>RESPONSIBILITY</h4>
                            {/* <h4 className='responsibility'>RESPONSIBILITY</h4> */}
                            {/* <h4 className='skills'>SKILLS REQUIRED</h4> */}
                            {/* <h4 className='qualifications'>QUALIFICATIONS</h4> */}
                        </div>
                        <div className={changeClassName === 2 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(2)}>
                            <h4>SKILLS REQUIRED</h4>
                        </div>
                        <div className={changeClassName === 3 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(3)}>
                            <h4>QUALIFICATIONS</h4>
                        </div>
                        {
                            changeClassName === 1 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-responsibility" id='reponsibility'>
                                        <ul>
                                            <li>Develop the ability to learn and adapt to newer tools in the relevant technical field.</li>
                                            <li>Data gathering through research.</li>
                                            <li>Adhere and study the technical instructions.</li>
                                            <li>Accountability of tools and simulation software of relevant technologies provided by the company for data analysis.</li>
                                            <li>Working with cross-team.</li>
                                            <li>Problem-solving attitude towards comprehending complex datasets and providing possible solutions.</li>
                                            <li>Basic hands-on experience with relevant technical tools.</li>
                                            <li>Commitment to provide end-to-end documentation without backing out.</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                        {
                            changeClassName === 2 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-skills-required" id='skills-required'>
                                        <ul>
                                            <li>Research & Writing</li>
                                            <li>Computer Skills</li>
                                            <li>Content Development</li>
                                            <li>Fluency in English</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                        {
                            changeClassName === 3 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-qualifications" id='qualifications'>
                                        <ul>
                                            <li>Bachelor's</li>
                                            <li>Master's</li>
                                            <li>PhD</li>
                                            <li>Other Professional Degree</li>
                                        </ul>
                                        <div className="resume-subjects-div">
                                            <h5>SUBJECTS</h5>
                                            <ul>
                                                <li>Maths | Accounts | Finance | Science | Statistics | Economics | Engineering etc.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </> : ""
                        }
                    </div>
                </div>
            </section>
            <section className="current-openings-button-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 current-openings-button-div">
                            <NavLink to={"/touchbase"} className="href"><button className="join-the-tribe">Join The Tribe</button></NavLink>
                            <NavLink to={"/careers"} className="href"><button className="other-openings"><span className='icon'><FaArrowLeft /></span> Other Openings</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TechnicalContentWriters;