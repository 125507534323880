import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RDayImage1 from "../../../../../../Assets/Events/2022/Womens-Day-2022/9.1.png";
import RDayImage2 from "../../../../../../Assets/Events/2022/Womens-Day-2022/9.2.png";
import RDayImage3 from "../../../../../../Assets/Events/2022/Womens-Day-2022/9.3.png";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const RepublicDay2023 = () => {
  return (
    <>
      <section className="carousel_slider_section">
        <div className="container carouselSliderFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  // slideShadows: true,
                }}
                // pagination={{
                //     clickable: true,
                //     dynamicBullets: true,
                // }}
                loop={true}
                autoplay={{ delay: 3000 }}
                navigation={true}
                modules={[EffectCoverflow, Navigation, Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage1} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage2} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage3} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage1} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage2} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage3} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 blog-heading-and-para-div">
              <h1>Women's Day</h1>
              <p>
                International Women's Day marks the celebration of women in
                every sphere of life. Women can be represented with different
                adjectives, but a true representation of Woman is through her
                Strength, divinity and purpose. Women have crossed through
                myriad hurdles to make their mark on the global platform. From
                being submissive to outspoken and demanding her rights to be
                treated equally in all the spectrums of life, she has created a
                niche platform. The " She " makes her beautiful, strong, and yet
                sensitive.
              </p>
              <p>
                Holistic learning celebrated Women's day 2022 with a "Born to
                Win" theme by saluting this spirit of womanhood. Extending this
                theme and providing a more practical perspective, the company
                has 70 % women employment, not by compulsion but through choice.
                The event began by cutting a cake and offering a bouquet to each
                female employee. Later, the founder Deepak Baid addressed the
                importance of Women both in his personal life and professional
                life. He congratulated each women employee of the company for
                contributing immensely and making the company grow to its zenith
                and for each constructively contributing to our society.
              </p>
              <p>
                "The empowered woman is powerful beyond measure and beautiful
                beyond description."
              </p>
            </div>
            <div className="col-md-6 blog-writer-div">
              <p>Written By ~ Shruti C</p>
            </div>
            <div className="col-md-6 blog-button-div">
              <NavLink to={"/events/more-events"} className="href">
                <button>
                  <span className="icon">
                    <FaArrowLeft />
                  </span>{" "}
                  View All Events
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RepublicDay2023;
