import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RDayImage1 from "../../../../../../Assets/Events/2022/Republic-Day-2022/8.1.png";
import RDayImage2 from "../../../../../../Assets/Events/2022/Republic-Day-2022/8.2.png";
import RDayImage3 from "../../../../../../Assets/Events/2022/Republic-Day-2022/8.3.png";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const RepublicDay2022 = () => {
  return (
    <>
      <section className="carousel_slider_section">
        <div className="container carouselSliderFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  // slideShadows: true,
                }}
                // pagination={{
                //     clickable: true,
                //     dynamicBullets: true,
                // }}
                loop={true}
                autoplay={{ delay: 3000 }}
                navigation={true}
                modules={[EffectCoverflow, Navigation, Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage1} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage2} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage3} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage1} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage2} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage3} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 blog-heading-and-para-div">
              <h1>Republic Day 2022</h1>
              <p>
                One of the most superfluous and dynamic events in India is
                Republic Day, a day when every citizen of India solemnizes the
                moment of being independent, enjoying all the fundamental rights
                and liberties. Holistic learning is a team of diverse talents
                and skills, celebrating every event and festival wholeheartedly
                with love and devotion. Accordingly, Republic Day is also
                celebrated on 26th January every year with lots of hope, joy,
                and happiness spread all over the nation.
              </p>
              <p>
                On this day, the entire team of holistic learning gives tribute
                to the nation and every personality who contributed to gaining
                complete Independence from colonial rule. The entire team
                celebrates by hosting a flag and singing the National Anthem.
                Few members enhance their creativity by making rangoli based on
                the theme of the event.
              </p>
              <p>
                Eventually, all the team members share their views on the
                auspicious and most significant event on Republic day and end
                the day with delicacies, peace, and happiness with a motto that
                the team ensures to contribute in some way or the other to the
                progress of the country in the coming years.
              </p>
            </div>
            <div className="col-md-6 blog-writer-div">
              <p>Written By ~ Swagata L B</p>
            </div>
            <div className="col-md-6 blog-button-div">
              <NavLink to={"/events/more-events"} className="href">
                <button>
                  <span className="icon">
                    <FaArrowLeft />
                  </span>{" "}
                  View All Events
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RepublicDay2022;
