import React from "react";
import { Helmet } from "react-helmet";
import MeetTheTribe from "../../Assets/Our_Team/Creative team.gif";
import MeetOurTeamChief from "../../Data/MeetOurTeam/MeetOurTeamChief";
import MeetOurTeamClan from "../../Data/MeetOurTeam/MeetOurTeamClan";
import "../CSS/OurTeam.css";

const OurTeam = () => {
  return (
    <>
      <Helmet>
        <title>Holistic Learning : Our Team</title>
        {/* <meta name="description" content="Welcome to Holistic Learning, your destination for content writing career opportunities in Bangalore." /> */}
        <link rel="canonical" href="https://holisitclearning.com/our-team" />
      </Helmet>
      <section className="meet-the-tribe-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6 meet-the-tribe-heading-and-para-div">
              <div className="meet-the-tribe-heading-and-para-first-inner-div">
                <h2>
                  Meet The <span>TRIBE</span>
                </h2>
                <p>
                  Coming together is a beginning, staying together is progress,
                  and working together is success
                </p>
              </div>
            </div>
            <div className="col-lg-6 meet-the-tribe-image-div">
              <img src={MeetTheTribe} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      {/* Meet Our Team - Leadership Section */}
      <section className="meet-our-tribe-chief-section-start-here">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 meet-our-tribe-chief-heading-div">
              <h2>TRIBAL CHIEF</h2>
            </div>
            {MeetOurTeamChief.map((items) => {
              return (
                <div
                  className="col-sm-6 col-md-6 col-lg-5 col-xl-4 meet-our-tribe-chief-first-div"
                  key={items.id}
                >
                  <div className="flip-box">
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <img
                          src={items.clanImageFront}
                          alt="Meet Our Team - Holistic Learning"
                          className="img-fluid"
                        />
                        <div className="heading-para-div-for-front">
                          <h4>{items.clanHeading}</h4>
                          <p>{items.clanParaFront}</p>
                        </div>
                      </div>
                      <div className="flip-box-back">
                        <img
                          src={items.clanImageBack}
                          alt="Meet Our Team - Holistic Learning"
                          className="img-fluid"
                        />
                        <div className="heading-para-div-for-front">
                          <h4>{items.clanHeading}</h4>
                          <p>{items.clanParaBack}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="meet-our-tribe-clan-section-start-here">
        <div className="container-fluid">
          <div className="row justify-flex-start">
            <div className="col-md-12 meet-our-tribe-clan-heading-div">
              <h2>
                Our <span>CLAN</span>
              </h2>
            </div>
            {MeetOurTeamClan.map((item) => {
              return (
                <div
                  className="col-xl-3 col-lg-4 col-md-4 col-sm-6 meet-our-tribe-clan-first-div"
                  key={item.id}
                >
                  <div className="meet-our-tribe-clan-flip-box">
                    <div className="flip-box-inner">
                      <div className="flip-box-front">
                        <img
                          src={item.clanImageFront}
                          alt="Meet Our Team - Holistic Learning"
                          className="img-fluid"
                        />
                        <div className="heading-para-div-for-front">
                          <h4>{item.clanHeading}</h4>
                          <p>{item.clanParaFront}</p>
                        </div>
                      </div>
                      <div className="flip-box-back">
                        <img
                          src={item.clanImageBack}
                          alt="Meet Our Team - Holistic Learning"
                          className="img-fluid"
                        />
                        <div className="heading-para-div-for-front">
                          <h4>{item.clanHeading}</h4>
                          <p>{item.clanParaBack}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default OurTeam;
