import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import FDeveloper from "../../../../Assets/Careers/Openings/Front-end_Developer.gif";
import "../CSS/CurrentOpenings.css";

const FrontendDevelopers = () => {
    const [changeClassName, setChangeClassName] = useState(1);
    const handleShowDiv = (e) => {
        setChangeClassName(e);
    }
    return (
        <>
            <Helmet>
                <title>Holistic Learning : Front-end Developer</title>
                <meta name="description" content="Welcome to Holistic Learning, your destination for content writing career opportunities in Bangalore." />
                <link rel="canonical" href="https://holisitclearning.com/careers/front-end-developers" />
            </Helmet>
            <section className="current-openings-first-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-6 current-openings-image-div">
                            <img src={FDeveloper} alt="" className='img-fluid' />
                        </div>
                        <div className="col-md-6 current-openings-heading-and-para-div">
                            <div className="current-openings-heading-and-para-first-inner-div">
                                <h1>FRONT-END DEVELOPERS</h1>
                                <p>As a Front-end developer you will be developing a product from scratch and are responsible for implementing visual elements that users see and interact within a web application. You should be able to translate our company and customer needs into functional and appealing interactive applications.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="current-openings-job-resume-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className={changeClassName === 1 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(1)}>
                            <h4>RESPONSIBILITY</h4>
                            {/* <h4 className='responsibility'>RESPONSIBILITY</h4> */}
                            {/* <h4 className='skills'>SKILLS REQUIRED</h4> */}
                            {/* <h4 className='qualifications'>QUALIFICATIONS</h4> */}
                        </div>
                        <div className={changeClassName === 2 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(2)}>
                            <h4>SKILLS REQUIRED</h4>
                        </div>
                        <div className={changeClassName === 3 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(3)}>
                            <h4>QUALIFICATIONS</h4>
                        </div>
                        {
                            changeClassName === 1 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-responsibility" id='reponsibility'>
                                        <ul>
                                            <li>Ensure high quality graphic standards and brand consistency.</li>
                                            <li>Assure that all user input is validated before submitting to back-end.</li>
                                            <li>Design mobile-based features.</li>
                                            <li>Optimize application for maximum speed and scalability.</li>
                                            <li>Create quality mock-ups and prototypes.</li>
                                            <li>Build reusable code and libraries for future use.</li>
                                            <li>Use markup languages like HTML to create user-friendly web pages.</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                        {
                            changeClassName === 2 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-skills-required" id='skills-required'>
                                        <ul>
                                            <li>Knowledge of SEO principles.</li>
                                            <li>Experience with JavaScript, CSS and jQuery.</li>
                                            <li>Good problem-solving skills.</li>
                                            <li>Excellent verbal communication skills.</li>
                                            <li>Determining the structure and design of web pages.</li>
                                            <li>Proficient understanding of web markup, including HTML.</li>
                                            <li>Technical feasibility of UI/UX designs.</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                        {
                            changeClassName === 3 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-qualifications" id='qualifications'>
                                        <ul>
                                            <li>Bachelor's</li>
                                            <li>Master's</li>
                                            <li>PhD</li>
                                            <li>Other Professional Degree</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                    </div>
                </div>
            </section>
            <section className="current-openings-button-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 current-openings-button-div">
                            <NavLink to={"/touchbase"} className="href"><button className="join-the-tribe">Join The Tribe</button></NavLink>
                            <NavLink to={"/careers"} className="href"><button className="other-openings"><span className='icon'><FaArrowLeft /></span> Other Openings</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FrontendDevelopers;