
const EveningOutingData21 = [
    {
        id: 1,
        imageSource: require("../../../Assets/Events/2021/Evening-Outing-2021/2.1.png")
    },
    {
        id: 2,
        imageSource: require("../../../Assets/Events/2021/Evening-Outing-2021/2.2.png")
    },
    {
        id: 3,
        imageSource: require("../../../Assets/Events/2021/Evening-Outing-2021/2.3.png")
    },
    {
        id: 4,
        imageSource: require("../../../Assets/Events/2021/Evening-Outing-2021/2.4.png")
    }
];

export default EveningOutingData21;