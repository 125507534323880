import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import EveningOutingData21 from '../../../../../../Data/Events/2021/EveningOutingData21';
import "../../CSSBlogs/MainBlogsCSSFile.css";

const EveningOuting2021 = () => {
  return (
    <>
    <section className="carousel_slider_section">
        <div className="container carouselSliderFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  // slideShadows: true,
                }}
                // pagination={{
                //     clickable: true,
                //     dynamicBullets: true,
                // }}
                loop={true}
                autoplay={{ delay: 3000 }}
                navigation={true}
                modules={[EffectCoverflow, Navigation, Autoplay]}
                className="mySwiper"
              >
                {EveningOutingData21.map((items) => {
                  return (
                    <SwiperSlide className="swiperSlide" key={items.id}>
                      <div className="swiper-slide-img">
                        <img
                          src={items.imageSource}
                          alt="Avatar"
                          className="nexus"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-md-12 blog-heading-and-para-div">
                    <h1>Evening Outing</h1>
                    <p>How do I put it! Uhm! An evening outing is just an obscure way to call it! But I must say - the day was very special for everyone. How special? Well, to start with - we had very little work to do for the day; both our founders were there with us (In the mood to have fun); every employee dressed up for the mood; it was also the day when we had planned to celebrate Holistic Learning's 8th anniversary! Putting it all together, it certainly called for a party! After each one got a pick of their own party place, it all dialed down to Mudpipe cafe! The place was gloomy yet vivid led lighting with a foosball table, and a remix beat at the backdrop to give the place a stereotype-free cultural mood set.</p>
                    <p>Have you ever wondered about playing foosball with your boss? It happened for us! and he got to beat us hands down. Shruti, on the other hand, was our food selector; she's got the best palate amongst us; with hot sizzlers, melting cheesy appetizers, fresh salads to cool down the spicy punch, and cool icy drinks to call the evening a relaxing farm, to top it off - a cold drizzle on the outside to say Buon appetito!</p>
                    <p>We got a shot at making a toast for what we were thankful for; the best speech was from one of our founders, Deepak; he ended it with being thankful for every employee to be a part of his firm!</p>
                </div>
                <div className="col-md-6 blog-writer-div">
                    <p>Written By ~ Punith R</p>
                </div>
                <div className="col-md-6 blog-button-div">
                    <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default EveningOuting2021;