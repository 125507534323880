import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RDayImage1 from "../../../../../../Assets/Events/2023/Holi_2023/Holi_1.webp";
import RDayImage2 from "../../../../../../Assets/Events/2023/Holi_2023/Holi_2.webp";
import RDayImage3 from "../../../../../../Assets/Events/2023/Holi_2023/Holi_3.webp";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const Holi2023 = () => {
    return (
        <>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2,
                                    // slideShadows: true,
                                }}
                                // pagination={{
                                //     clickable: true,
                                //     dynamicBullets: true,
                                // }}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage1}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage2}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage3}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage1}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage2}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage3}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs-heading-and-para-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blog-heading-and-para-div">
                            <h1>Holi #2023</h1>
                            <p>Holi is a widely celebrated event that brings joy, happiness, and a sense of unity. This traditional Hindu festival, also known as the festival of spring or the festival of colors, marks the arrival of a new season and is a time for all to come together, forget their differences and resentments, and share in the celebration of the vibrant colors of spring. On this day, people take to the streets to throw colored powders and water balloons, creating a joyful and lively atmosphere. Holi is a day of fun and laughter; everyone is invited to join the festivities. In 2023 Sense, Both Holi & Women's Day is on the same day. It was decided to celebrate both on the 07th of March.</p>
                            <p>Celebrations began with lunch. After finishing the lunch as planned earlier, we started to make colorful hand prints on the white cardboard sheet. Then we played the Sequence game, and the celebration commenced with the drinking of Thandai, a traditional Indian beverage adorned with almonds and pistachios that is known for its invigorating properties. We prepare to color each other with a game called Strops. In this game, the player is presented with a series of words that are written in different colors. The challenge is to correctly identify the color of the text as quickly as possible while ignoring the word that is written. The game can be timed, and scores can be compared to see who can complete the test with the highest accuracy and speed. Celebrated holi coloring, everyone.</p>
                            <p>As a part of Women's Day in the office, we gifted all women in the office with Red roses and Dairy Milk Chocolates.</p>
                        </div>
                        <div className="col-md-6 blog-writer-div">
                            <p>Written By ~ M G Vishwas</p>
                        </div>
                        <div className="col-md-6 blog-button-div">
                            <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Holi2023;