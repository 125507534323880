
const OfficeDayData22 = [
    {
        id: 1,
        imageSource: require("../../../Assets/Events/2022/Office_Day_2022/Office_Day_1.png")
    },
    {
        id: 2,
        imageSource: require("../../../Assets/Events/2022/Office_Day_2022/Office_Day_2.png")
    },
    {
        id: 3,
        imageSource: require("../../../Assets/Events/2022/Office_Day_2022/Office_Day_3.png")
    },
    {
        id: 4,
        imageSource: require("../../../Assets/Events/2022/Office_Day_2022/Office_Day_4.png")
    },
    {
        id: 5,
        imageSource: require("../../../Assets/Events/2022/Office_Day_2022/Office_Day_5.png")
    }
];

export default OfficeDayData22;