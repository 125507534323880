import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import DiwaliData23 from '../../../../../../Data/Events/2023/DiwaliData23';
import "../../CSSBlogs/MainBlogsCSSFile.css";

const Diwali2023 = () => {
    return (
        <>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2,
                                    // slideShadows: true,
                                }}
                                // pagination={{
                                //     clickable: true,
                                //     dynamicBullets: true,
                                // }}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                {
                                    DiwaliData23.map((items) => {
                                        return (
                                            <SwiperSlide className="swiperSlide" key={items.id}>
                                                <div className="swiper-slide-img">
                                                    <img src={items.imageSource} alt="Avatar" className='nexus' />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs-heading-and-para-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blog-heading-and-para-div">
                            <h1>Happy Diwali #2023</h1>
                            <p>We celebrate Diwali to celebrate the triumph of light over darkness and good over evil. Like every year, we at Holistic Learning celebrated Diwali with much enthusiasm. We decorated the office with fairy lights and oil lamps, which illuminated the entire office, making the environment festive.</p>
                            <p>Diwali is incomplete without a rangoli, and we did our best to make the rangoli with bright colors, which turned out to be captivating as the rangoli has a greater significance in Indian households. It is a type of decoration with the purpose of bringing prosperity and good luck to the household. Therefore, to add to our festive mode, we decorated our rangoli with the same spirit and excitement. We started our Diwali 2023 event with the Puja, we prayed to Goddess Lakshmi on the auspicious occasion of Diwali, as she is the central deity of prosperity and wealth. We offered our prayers and sought blessings for our well-being and financial growth.</p>
                            <p>After having an enticing breakfast, we straight away moved to our event, which involved creativity, teamwork, and problem-solving. One such activity was making a creative art piece using origami paper. It was a fun activity that every member of the office enjoyed.</p>
                            <p>We relived our childhood with the first activity; each one of us did our best to come up with creative art made out of paper. Some of the art pieces were frogs, boats, planes, hearts, etc. Everyone in the office enjoyed the activity, with their best interest involved in making those colorful pieces. After the first few activities, we played many other games, such as Dumb charads, which involved the name of the personality, movies, and TV shows through acting. In this game, a person has to use their body language, facial expression, and other gestures to play. The activities and games definitely encouraged a competitive spirit, and they allowed us to experiment through trial and error.</p>
                            <p>We ensured that whatever game we included should help increase planning skills, communication, employee collaboration, and employee motivation. Therefore, we ended our day with the firecracker and a bag full of memories.</p>
                        </div>
                        <div className="col-md-6 blog-writer-div">
                            <p>Written By ~ Ankita D</p>
                        </div>
                        <div className="col-md-6 blog-button-div">
                            <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Diwali2023;