import { NavLink } from "react-router-dom";
import BannerImg1 from "../../../Assets/Events/2023/Banner_Images/CSR_banner_1.webp";

const CSR2023Banner = [
    {
        id: 1,
        bannerImage: <NavLink to={"/it-room-setup-2023"} className="href"><img src={BannerImg1} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/it-room-setup-2023"} className="href">IT Room Setup in G.P.H.S. School in Tumkur (Karnataka)</NavLink></h4>
    }
];

export default CSR2023Banner;