import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RDayImage1 from "../../../../../../Assets/Events/2023/Outing_12th_Fail_Film/MOVIE 1.webp";
import RDayImage2 from "../../../../../../Assets/Events/2023/Outing_12th_Fail_Film/MOVIE 2.webp";
import RDayImage3 from "../../../../../../Assets/Events/2023/Outing_12th_Fail_Film/MOVIE 3.webp";
import "../../CSSBlogs/MainBlogsCSSFile.css";
// SwiperCore.use([Autoplay]);

const OutingFor12thFailFilm = () => {
    return (
        <>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2,
                                    // slideShadows: true,
                                }}
                                // pagination={{
                                //     clickable: true,
                                //     dynamicBullets: true,
                                // }}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage1}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage2}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage3}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage1}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage2}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage3}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs-heading-and-para-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blog-heading-and-para-div">
                            <h1>Clocking-out-for-reel-time</h1>
                            <p>In the bustling world where the monotony of routine office life and deadlines are approaching, the idea of a movie hangout from the office may seem like an unusual escape; desks transform into ticket counters and fluorescent-lit cubicles. It all started with an innocuous plan: break free from the monotony and catch a movie.</p>
                            <p>We are breaking free from the hectic work of December, a back-to-back of nine-to-five hours of work. The chosen destination for our celluloid escape was the PVR theatre – a cinematic haven nestled in the city's heart. As we embarked on this collective journey, the prospect of watching the much-hyped "12th Fail" movie heightened our anticipation. We are transforming our typical office hangout into an unforgettable experience. This isn't just a place to catch up on the latest blockbusters; it's a shared experience that transcends professional boundaries, making colleagues feel more like friends.</p>
                            <p>As we entered the office, the hierarchy faded away. Seeing colleagues in a different light and sharing laughs over shared cinematic preferences was refreshing. The first surprise was realizing how much a change of scenery could enhance our bonds. The typical water cooler conversations became spirited discussions about the movie. The ambient noise of keyboard clicks and ringing phones fade into the background. Colleagues transport to a realm where deadlines and stress are forgotten, replaced by the captivating narratives unfolding on the screen. Laughter ripples throughout the room during amusing times, and the occasional scream vibrates when tale twists surprise everyone. It's more than a movie; it's a shared emotional experience developing colleague ties.</p>
                            <p>The movie hangout phenomenon is more than a transitory fade; it demonstrates the ever-changing nature of office relations. It challenges the traditional notion of professional spaces, proving that a thriving work environment goes beyond boardroom meetings and productivity charts. It's about creating a space where individuals can be themselves, build connections, and enjoy watching a good movie together. We realized that our movie hangouts were not just about escaping the monotony; they were about creating a mosaic of memories that would forever be etched in the corridors of our professional lives.</p>
                            <p>A place where coworkers become friends and collaboration extends beyond work-related tasks. It celebrates diversity, creativity, and shared experiences that breathe life into the corporate landscape. So, the next time you find yourself trapped in the monotony of office life, consider proposing a movie hangout—it might just be the cinematic escape your workplace needs. May the PVR screens continue to flicker with the magic of storytelling, weaving tales that go beyond the confines of cubicles and echo in the hearts of colleagues turned friends.</p>
                        </div>
                        <div className="col-md-6 blog-writer-div">
                            <p>Written By ~ Keerthana V</p>
                        </div>
                        <div className="col-md-6 blog-button-div">
                            <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OutingFor12thFailFilm;