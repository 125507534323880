import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import IDayData23 from '../../../../../../Data/Events/2023/IDayData23';
import "../../CSSBlogs/MainBlogsCSSFile.css";

const IndependenceDay2023 = () => {
    return (
        <>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2,
                                    // slideShadows: true,
                                }}
                                // pagination={{
                                //     clickable: true,
                                //     dynamicBullets: true,
                                // }}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                {
                                    IDayData23.map((items) => {
                                        return (
                                            <SwiperSlide className="swiperSlide" key={items.id}>
                                                <div className="swiper-slide-img">
                                                    <img src={items.imageSource} alt="Avatar" className='nexus' />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs-heading-and-para-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blog-heading-and-para-div">
                            <h1>Independence Day 2023</h1>
                            <p>On 10th August at Holistic Learning, the tribe of holistic learning gathered together to celebrate the sense of self-sustainability that we drive out of national independence. During our celebration of Indian Independence, we strive to dwell in the participatory global spirit of sharing the uniqueness of cultural values that would spread our awareness of the depth of our indigeneity and identity.</p>
                            <p>The events were held in the widely decorated space where the people of our holistic tribe shared many cultural ideas that united us to cherish our intercultural nourishments while listening to each other on their experience towards life in various cultural living.</p>
                            <p>In the evening, the tribe set out to express our significance of being socially and culturally independent and hold a sense of radicalism to introspect that our nation has strived to be an incredible land that shared ancient civilizational and cultural values. Besides the roots of our differentiated state origins, we strive to hold onto each other to achieve cultural learning outcomes by the effect of the cultural differences led by our lingual origins, theological influences, and cultural and demographic indigeneity.</p>
                            <p>As cultural recreations empower our social togetherness towards bringing the learning aspects concerning cultural diversification, we set our participation towards making our recreational evening joyful while indulging in educational and linguistic games and other fun-related activities. As socially responsible entities, we, the tribe of Holistic Learning, will strive to engage our social and economic participation toward our collective national interest.</p>
                        </div>
                        <div className="col-md-6 blog-writer-div">
                            <p>Written By ~ Joe Christopher</p>
                        </div>
                        <div className="col-md-6 blog-button-div">
                            <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IndependenceDay2023;