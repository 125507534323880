import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import AnniversaryDayData23 from '../../../../../../Data/Events/2023/AnniversaryDayData23';
import "../../CSSBlogs/MainBlogsCSSFile.css";
// SwiperCore.use([Autoplay]);

const AnniversaryDay2023 = () => {
    return (
        <>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2,
                                    // slideShadows: true,
                                }}
                                // pagination={{
                                //     clickable: true,
                                //     dynamicBullets: true,
                                // }}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                {
                                    AnniversaryDayData23.map((items) => {
                                        return (
                                            <SwiperSlide className="swiperSlide" key={items.id}>
                                                <div className="swiper-slide-img">
                                                    <img src={items.imageSource} alt="Avatar" className='nexus' />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs-heading-and-para-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blog-heading-and-para-div">
                            <h1>10<sup>th</sup> Anniversary of Holistic Learning</h1>
                            <p>In dwelling in the moments of the celebration of the 10th anniversary of our organization, we, the Holistic Learning tribe, cherish the academic values that help us evolve socially and culturally towards progressing the creation of utilities to humankind.</p>
                            <p>The emphasis on academic research and development holds the vitality of any society. We strive for the understanding that pursuits towards knowledge and informative awareness can lead to cultural adaptations, utopian and scientific developments, and economic transformations with outcomes relating to the creation of goods and services, engagement into mindful recreations, and entertainment.</p>
                            <p>As we live in an era of information, we strive to our foremost onus of expressing our regards towards academia. In this moment of pride, we acknowledge the contribution made by academic communities assisting social and cultural transformations across us, relating from the formation of theories to the application of knowledge, which renders utilities in a society.</p>
                            <p>The 16th of August, 2023, which was regarded as the 10th anniversary of our organization, had been spent with intellectual acquaintances sharing ideas and gaining inspiration from each other. Having been involved in sharing each other’s experiences in reaching significant milestones in our individual lives, we regard our comradeship as navigating us toward sustaining the heights of academic excellence, personal growth, and collective nourishment.</p>
                            <p>To the writers, research associates, scientists, theorists, students, and all other individuals in academia, we, the holistic learning tribe, express our gratitude for holding the service opportunity for a decade.</p>
                        </div>
                        <div className="col-md-6 blog-writer-div">
                            <p>Written By ~ Joe Chritopher</p>
                        </div>
                        <div className="col-md-6 blog-button-div">
                            <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AnniversaryDay2023;