import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import GenContentWriters from "../../../../Assets/Careers/Openings/General_Content_Writer.gif";
import "../CSS/CurrentOpenings.css";

const GeneralContentWriters = () => {
    const [changeClassName, setChangeClassName] = useState(1);
    const handleShowDiv = (e) => {
        setChangeClassName(e);
    }
    return (
        <>
            <Helmet>
                <title>Holistic Learning : General Content Writers</title>
                <meta name="description" content="Welcome to Holistic Learning, your destination for content writing career opportunities in Bangalore." />
                <link rel="canonical" href="https://holisitclearning.com/careers/general-content-writers" />
            </Helmet>
            <section className="current-openings-first-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 current-openings-image-div">
                            <img src={GenContentWriters} alt="" className='img-fluid' />
                        </div>
                        <div className="col-lg-6 current-openings-heading-and-para-div">
                            <div className="current-openings-heading-and-para-first-inner-div">
                                <h1>GENERAL CONTENT WRITERS</h1>
                                <p>Responsible for developing relevent content in their respective field of specialization as well as in other areas gradually. gradually. Candidates should have a knack for writing and good command over the subject as per their specialization.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="current-openings-job-resume-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className={changeClassName === 1 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(1)}>
                            <h4>RESPONSIBILITY</h4>
                            {/* <h4 className='responsibility'>RESPONSIBILITY</h4> */}
                            {/* <h4 className='skills'>SKILLS REQUIRED</h4> */}
                            {/* <h4 className='qualifications'>QUALIFICATIONS</h4> */}
                        </div>
                        <div className={changeClassName === 2 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(2)}>
                            <h4>SKILLS REQUIRED</h4>
                        </div>
                        <div className={changeClassName === 3 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(3)}>
                            <h4>QUALIFICATIONS</h4>
                        </div>
                        {
                            changeClassName === 1 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-responsibility" id='reponsibility'>
                                        <ul>
                                            <li>Writers should be able to meet the desired deadlines for the assigned tasks.</li>
                                            <li>Research only through scholarly and renowned books by well-established authors.</li>
                                            <li>To be able to engage oneself within a diverse team to produce creative and efficient content.</li>
                                            <li>The writer should be able to give due credit to information utilized from online resources; i.e., the content should be free of plagiarism and the influence of AI.</li>
                                            <li>Every piece of content should be proofread to check for grammar, citations, plagiarism, and AI rate.</li>
                                            <li>Writers should be able to take accountability in terms of delivering original and on-time content.</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                        {
                            changeClassName === 2 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-skills-required" id='skills-required'>
                                        <ul>
                                            <li>Research & Writing</li>
                                            <li>Computer Skills</li>
                                            <li>Content Development</li>
                                            <li>Fluency in English</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                        {
                            changeClassName === 3 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-qualifications" id='qualifications'>
                                        <ul>
                                            <li>Bachelor's</li>
                                            <li>Master's</li>
                                            <li>PhD</li>
                                            <li>Other Professional Degree</li>
                                        </ul>
                                        <div className="resume-subjects-div">
                                            <h5>SUBJECTS</h5>
                                            <ul>
                                                <li>H.R | Law | Arts | Nursing | Psychology | Literature | Communication | Business Management etc.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </> : ""
                        }
                    </div>
                </div>
            </section>
            <section className="current-openings-button-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 current-openings-button-div">
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfXtwTcicEwEf8HxgthaExd80u9qK9aiIHnIWecX_UVRXKxSw/viewform" className="href" target='_blank' rel='noopener noreferrer'><button className="join-the-tribe">Join The Tribe</button></a>
                            <NavLink to={"/careers"} className="href"><button className="other-openings"><span className='icon'><FaArrowLeft /></span> Other Openings</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GeneralContentWriters;