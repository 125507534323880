import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RDayImage1 from "../../../../../../Assets/Events/2022/Wayanad_Trip-2022/Wayanad_1.png";
import RDayImage2 from "../../../../../../Assets/Events/2022/Wayanad_Trip-2022/Wayanad_2.png";
import RDayImage3 from "../../../../../../Assets/Events/2022/Wayanad_Trip-2022/Wayanad_3.png";
import RDayImage4 from "../../../../../../Assets/Events/2022/Wayanad_Trip-2022/Wayanad_4.png";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const WayanadTrip2022 = () => {
    return (
        <>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2,
                                    // slideShadows: true,
                                }}
                                // pagination={{
                                //     clickable: true,
                                //     dynamicBullets: true,
                                // }}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img src={RDayImage1} alt="Avatar" className="nexus" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img src={RDayImage2} alt="Avatar" className="nexus" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img src={RDayImage3} alt="Avatar" className="nexus" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img src={RDayImage4} alt="Avatar" className="nexus" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img src={RDayImage2} alt="Avatar" className="nexus" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img src={RDayImage3} alt="Avatar" className="nexus" />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs-heading-and-para-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blog-heading-and-para-div">
                            <h1>Wayanad - Vythiri Holiday Resort #2022</h1>
                            <p>Have you ever imagined living in a resort between misty mountains and tall green trees? I know it's dreamy right? Holistic learning allowed us to live that dream.</p>
                            <p>Before we got started, the morning was drizzling wet, and the sun shied away from showing itself; it never stopped us from taking a detour to Bangalore Cafe for heaping hot idli's and masala dosa's, oh ya then filter coffee to call it a complete breakfast.</p>
                            <p>As a part of a team outing, we drove to Vythiri holiday resort, Wayanad. You never know what to expect when there's a travel on the card when our path has to collide with Bandipur National Park.</p>
                            <p>Although we were not able to find a tiger, we were lucky enough to catch sight of spotted deer all around the forest road and wild buffalos; it was quite spectacular because we live in an urban setting with buildings surrounding us all year long and driving through the lush green forest with hundreds of spotted deers welcoming us and to top it off with a sunset, it only made the winter better. By the time you know, we reached Wayanad; the place is within the forest; unlike buildings and hustling traffic noises, the Vythiri, Wayanad - was a breezy place with gloomy LEDs that screamed "vacation tiimmmee," as the night kicked in with cool temperature, it set up the mood for fun, the place never seemed to settle down, because of bustling tourists. I would like to add that we were sticking our faces to the window, soaking the beauty of the forest. Anytime we stopped, we could see sparrows flying around us. By the time we knew we had reached the resort, I must describe the entrance - it was pitch dark with tall bamboo trees covering what was ahead while small dwarf lamps good enough only to light the walkway before you knew we were down with our bag and the place was well lit with bright lights in-between the forest with a cold climate dropping down and suddenly a campfire caught our attention. We dropped the bags, put on a new pair of clothes, and hit the campfire; the resort was so big some of them even started to explore other amenities, where Debraj, Faisal, and Vishwas were playing table tennis, some were on video calls showing the stunning beauty of fog falling down and the rest of them settled down at the campfire.</p>
                            <p>With blazing firewood and boombox on the corner, we could not resist getting on our tippy toes; we danced to our favorite beats. Priyanka, Deepak, shruti, and Aparna put on a show; they were so good that I even lost my senses and sat on a creepy crawler and took a sting on my buttock; my night was a looott itchy after that.</p>
                            <p>We all gathered in a villa with a lot of crunchy munchies and a load of cards to play. Deepak had got a board game named "Sequence" we spent around four hours getting lost in getting our sequence of pawns rights, I don't want to boast, but Aparna and I came out as the winner #ProudFace. That was day one!</p>
                            <p>Then came the morning of day two when I, along with a few others (Debraj, Vishwas, Faisal, and Priyanka), those who were up early, took a hike around the resort and found a waterfall. How shall I describe it? Here - with misty morning fog, no people or vehicles passing by, the place was filled with birds chirping the wake-up call, trees dancing on a windy morning alongside dripping dews, bolder rocks stopping the flow of water and creating a small pond above all the water was so so clear, and if it needs to be defined in one word, I'd call it serenity.</p>
                            <p>By the time we were done with the waterfall, everyone was up, and we saw a badminton court - around foggy mountains and tall grown trees; the entire morning was a mere dream. We took a dunk in the swimming pool after an hour of battling shuttlecock, I wish the photos could justify what we witnessed, but if you take a look, you can see the backdrop of the pool, with palm trees and a lush green mountain; our vacation just got better. Like coffee to our bed, our breakfast buffet was next to the pool. The buffet was just perfect; it had the taste of local culture, steaming hot puttu that broke into powder just right enough to savor it with authentic kadla curry (Channa curry), idli with Kerala-style coconut chutney, boiled eggs, a pinch of western influence where fruit juice with bread and jam with a toaster around the corner, could our mornings get any better? Well, it did; we all got dressed up and did a small photo shoot exploring all the options each one of our cameras had, pretending to be professional photographers, but the real credit goes to the natural ecosystem around us; my words cannot bring it alive; I wish you could visit the place once in your lifetime, it's exactly the place you would dream or watch in a movie.</p>
                        </div>
                        <div className="col-md-6 blog-writer-div">
                            <p>Written By ~ Punith R A</p>
                        </div>
                        <div className="col-md-6 blog-button-div">
                            <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WayanadTrip2022;