import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import KnowUs2 from "../../Assets/Know_Us/Diary.gif";
import KnowUs1 from "../../Assets/Know_Us/New team members.gif";
import Quotes from "../../Assets/Know_Us/Notebook.gif";
import WAnkita from "../../Assets/Our_Team/Colleague-Pics/Ankita-D.webp";
import WGowthami from "../../Assets/Our_Team/Colleague-Pics/Gowthami-N.jpeg";
import WKeerthana from "../../Assets/Our_Team/Colleague-Pics/Keerthana-V.png";
import WPunith from "../../Assets/Our_Team/Colleague-Pics/Punith-R.jpeg";
import "../CSS/KnowUs.css";

const KnowUs = () => {
  return (
    <>
      <Helmet>
        <title>Holistic Learning : Know us</title>
        {/* <meta name="description" content="Welcome to Holistic Learning, your destination for content writing career opportunities in Bangalore." /> */}
        <link rel="canonical" href="https://holisitclearning.com/know-us" />
      </Helmet>
      <section className="who-are-we-section-on-know-us-page-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6 who-are-we-on-know-us-page-image-div">
              <img src={KnowUs1} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 who-are-we-on-know-us-page-heading-and-para-div">
              <h2>
                <span>WHO</span> Are We?
              </h2>
              <p>
              We are a team with diverse hobbies - travelling enthusiasts, singers, sketch artists, poets, photographers, etc. - all linked with a shared passion - a passion to write. The team is connected through writing - some are into hard core writing, some are hiring writers and some develop and market websites that promote our writing.</p>
              <p>The Tribe, as we fondly call ourselves, is diverse not only in terms of hobby but in terms of educational background and geographic location. From MBA in HR to MA in English, from MTech to MSc we have them all in our team. Even geographically Holistic Learning has a presence from East to West and North to South. A diverse team bringing diverse ideas and capabilities to the company.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="what-we-do-section-on-know-us-page-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6 what-we-do-on-know-us-page-heading-and-para-div">
              <h2>
                <span>WHAT</span> We Do?
              </h2>
              <p>We understand the importance of well written content – be it for a college going student or for a website that reflects the ethos of the company or a creative article for a blog. Our team understands the requirements in depth and delivers content that exceeds client expectations for mediocrity is something we do not believe in.</p>
              <p>Our team understands the importance of writing - a well written piece not only transmits the intended message to the reader but also creates a deep-rooted long-lasting relationship with the reader. And that is what Holistic means in a philosophical sense - a belief that the parts of something are intimately interconnected.</p>
              <p>With each piece we write, we learn something new - both in terms of the topic being addressed and in terms of the writing process. Learning, at Holistic Learning, never stops. We truly believe in what Voltaire, French Enlightenment writer, said - writing is the painting of the voice.</p>
            </div>
            <div className="col-lg-6 what-we-do-on-know-us-page-image-div">
              <img src={KnowUs2} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <section className="know-us-quote-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-4 know-us-quote-image-div">
              <img src={Quotes} alt="" className="img-fluid" />
            </div>
            <div className="col-md-8 know-us-quote-content-div">
              <div className="know-us-quote-content-first-inner-div">
                <h3><span>WRITING</span> is the painting of the voice</h3>
                <p>- Voltaire</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="people-who-contribute-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-evenly">
            <div className="col-md-12 people-who-contribute-heading-div">
              <h2>People Who <span>CONTRIBUTE</span></h2>
            </div>
            <div className="col-md-3 col-sm-6 col-6 people-who-contribute-image-div">
              <div className="people-who-contribute-first-inner-div">
                <img src={WPunith} alt="" className="img-fluid" />
                <NavLink to={"/our-team"} className="href"><p>Content Writer</p></NavLink>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6 people-who-contribute-image-div">
              <div className="people-who-contribute-first-inner-div">
                <img src={WGowthami} alt="" className="img-fluid" />
                <NavLink to={"/our-team"} className="href"><p>Content Writer</p></NavLink>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6 people-who-contribute-image-div">
              <div className="people-who-contribute-first-inner-div">
                <img src={WKeerthana} alt="" className="img-fluid" />
                <NavLink to={"/our-team"} className="href"><p>Content Writer</p></NavLink>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6 people-who-contribute-image-div">
              <div className="people-who-contribute-first-inner-div">
                <img src={WAnkita} alt="" className="img-fluid" />
                <NavLink to={"/our-team"} className="href"><p>Content Writer</p></NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default KnowUs;
