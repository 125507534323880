import { NavLink } from "react-router-dom";
import BannerImg1 from "../../../Assets/Events/2023/Banner_Images/banner_16.webp";
import BannerImg5 from "../../../Assets/Events/2023/Banner_Images/banner_17.webp";
import BannerImg3 from "../../../Assets/Events/2023/Banner_Images/banner_18.webp";
import BannerImg2 from "../../../Assets/Events/2023/Banner_Images/banner_19.webp";
import BannerImg6 from "../../../Assets/Events/2023/Banner_Images/banner_20.webp";
import BannerImg7 from "../../../Assets/Events/2023/Banner_Images/banner_21.webp";
import BannerImg4 from "../../../Assets/Events/2023/Banner_Images/banner_22.webp";
import BannerImg8 from "../../../Assets/Events/2023/Banner_Images/banner_23.webp";
import BannerImg9 from "../../../Assets/Events/2023/Banner_Images/banner_24.webp";

const Events2023Banner = [
    {
        id: 1,
        bannerImage: <NavLink to={"/republic-day-2023"} className="href"><img src={BannerImg1} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/republic-day-2023"} className="href">Republic Day</NavLink></h4>
    },
    {
        id: 2,
        bannerImage: <NavLink to={"/holi-2023"} className="href"><img src={BannerImg2} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/holi-2023"} className="href">Happy Holi</NavLink></h4>
    },
    {
        id: 3,
        bannerImage: <NavLink to={"/independence-day-2023"} className="href"><img src={BannerImg3} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/independence-day-2023"} className="href">Independence Day</NavLink></h4>
    },
    {
        id: 4,
        bannerImage: <NavLink to={"/happy-10th-anniversary-day-2023"} className="href"><img src={BannerImg4} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/happy-10th-anniversary-day-2023"} className="href">Happy 10th Anniversary Day</NavLink></h4>
    },
    {
        id: 5,
        bannerImage: <NavLink to={"/happy-karnataka-day-2023"} className="href"><img src={BannerImg5} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/happy-karnataka-day-2023"} className="href">Kannada Rajyotsava</NavLink></h4>
    },
    {
        id: 6,
        bannerImage: <NavLink to={"/diwali-2023"} className="href"><img src={BannerImg6} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/diwali-2023"} className="href">Happy Diwali</NavLink></h4>
    },
    {
        id: 7,
        bannerImage: <NavLink to={"/clocking-out-for-reel-time"} className="href"><img src={BannerImg9} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/clocking-out-for-reel-time"} className="href">Clocking out for reel time!</NavLink></h4>
    },
    {
        id: 8,
        bannerImage: <NavLink to={"/christmas-day-2023"} className="href"><img src={BannerImg8} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/christmas-day-2023"} className="href">Merry Christmas</NavLink></h4>
    },
    {
        id: 9,
        bannerImage: <NavLink to={"/birthday-celebration-2023"} className="href"><img src={BannerImg7} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/birthday-celebration-2023"} className="href">Birthday Celebrations</NavLink></h4>
    }
];

export default Events2023Banner;