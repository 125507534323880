import { onValue, ref } from 'firebase/database'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { db } from '../../firebase.js'
import "../CSS/Musings.css"

const Musings = () => {
    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const userRef = ref(db, 'musingsDataCollection');
        onValue(userRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const dataArray = Object.values(data)
                setUserData(dataArray)
                console.log(dataArray)
            }
        })
    }, [])
    return (
        <>
            <Helmet>
                <title>Holistic Learning : Musings</title>
                {/* <meta
                    name="description"
                    content="We at Holistic Learning offer students help with their assignments, homework and online courses. We assure grade A or B or money back."
                /> */}
                <link rel="canonical" href="https://holisitclearning.com/musings" />
            </Helmet>
            <section className="musings-section-start-here">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 musings-heading-div">
                            <h2><span>DAILY</span> Learnings</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="musings-quotes-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        {
                            userData.slice(0).reverse().map((items) => {
                                return (
                                    <div className="col-md-12 musings-quote-main-div" key={items.userId}>
                                        <div className="musings-quote-first-inner-div">
                                            <div className="musings-quote-date-div">
                                                <p>{items.date}</p>
                                            </div>
                                            <div className="musings-quotes-div">
                                                <h2>&#x275D;{items.quote}&#x275E;</h2>
                                            </div>
                                            <div className="musings-written-by-and-tribe-member-div">
                                                <p className="tribe-member-para">Tribe Member ~ {items.name}</p>
                                                <p className="written-by-para">Written By ~ {items.whoseQuote}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Musings