
const DiwaliData21 = [
    {
        id: 1,
        imageSource: require("../../../Assets/Events/2021/Diwali-2021/1.1.png")
    },
    {
        id: 2,
        imageSource: require("../../../Assets/Events/2021/Diwali-2021/1.2.png")
    },
    {
        id: 3,
        imageSource: require("../../../Assets/Events/2021/Diwali-2021/1.3.png")
    },
    {
        id: 4,
        imageSource: require("../../../Assets/Events/2021/Diwali-2021/1.4.png")
    }
];

export default DiwaliData21;