import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RDayImage1 from "../../../../../../Assets/Events/2023/Republic_Day_2023/Republic_Day_1.webp";
import RDayImage2 from "../../../../../../Assets/Events/2023/Republic_Day_2023/Republic_Day_2.webp";
import RDayImage3 from "../../../../../../Assets/Events/2023/Republic_Day_2023/Republic_Day_3.webp";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const RepublicDay2023 = () => {
    return (
        <>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2,
                                    // slideShadows: true,
                                }}
                                // pagination={{
                                //     clickable: true,
                                //     dynamicBullets: true,
                                // }}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage1}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage2}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage3}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage1}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage2}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage3}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs-heading-and-para-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blog-heading-and-para-div">
                            <h1>Republic Day 2023</h1>
                            <p>
                                The new year at Holistic Learning began with the first office
                                event on the calender, but with a twist. On 24th January, the
                                team came together to celebrate not one but two important days -
                                Republic Day and Saraswati Pooja.
                            </p>
                            <p>
                                The preparation began the previous evening with everyone
                                participating in an office-wide decoration spree. After more
                                than two hours of contemplating and after countless balloons
                                became martyrs to the excitement and chaos, the office was
                                ready. And so were we.
                            </p>
                            <p>
                                No traditional celebration goes without the famous art of
                                Rangoli, which also welcomes prosperity and enlightenment on any
                                auspicious day. After the office was graced with a beautifully
                                drawn Rangoli, the day began with the lamp lighting and Pooja
                                ceremony, signifying spreading of light and channeling positive
                                energy.
                            </p>
                            <p>
                                The team then convened to talk about our nation and share
                                insights and knowledge about the significance of Republic Day.
                                We discussed the aspects of what made our country exceptional,
                                what was unique and how, even though we are all different, we
                                strive to come together as one. The discussions also imparted in
                                us the idea that even though each of us could be from another
                                part of the country, speak different languages, follow different
                                cultures, different belief systems, we are all here now and
                                working together in one environment that respects all of these
                                differences. It reminded us how our nation continues to be
                                diverse and unified amidst the variations.
                            </p>
                            <p>
                                This was followed by the 'second most looked forward to' part of
                                the day, which was lunch. After stuffing ourselves with heaps
                                and heaps of delicious food, we were definitely ready for the
                                'most looked forward to' part of the day- activities! Or were
                                we?
                            </p>
                            <p>
                                Finally, armed with the belief that we can physically move after
                                a heavy meal, we set ourselves to enjoy the rest of the day with
                                a variety of games and other activities which involved actually
                                moving, which proved to be more challenging than we thought. We
                                ended our celebration on the note of being grateful for our
                                opportunities, staying inspired and enjoying good company.
                            </p>
                        </div>
                        <div className="col-md-6 blog-writer-div">
                            <p>Written By ~ Hitha S</p>
                        </div>
                        <div className="col-md-6 blog-button-div">
                            <NavLink to={"/events/more-events"} className="href">
                                <button>
                                    <span className="icon">
                                        <FaArrowLeft />
                                    </span>{" "}
                                    View All Events
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default RepublicDay2023;
