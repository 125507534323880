

const FreelancingOpportunities = [
    {
        id: 1,
        permanentEmployeeIcon: require("../../Assets/Careers/Freelancing_Opportunities/Degrees_Holder.png"),
        para: "Bachelors & Masters Degree Holders"
    },
    {
        id: 2,
        permanentEmployeeIcon: require("../../Assets/Careers/Freelancing_Opportunities/No_Plagiarism.png"),
        para: "Original Content; No Plagiarism"
    },
    {
        id: 3,
        permanentEmployeeIcon: require("../../Assets/Careers/Freelancing_Opportunities/Writing_Skills.png"),
        para: "Good Writing Skills"
    },
    {
        id: 4,
        permanentEmployeeIcon: require("../../Assets/Careers/Freelancing_Opportunities/English.png"),
        para: "Excellent Command Over English"
    },
    {
        id: 5,
        permanentEmployeeIcon: require("../../Assets/Careers/Freelancing_Opportunities/Referencing_Format.png"),
        para: "Know-how of referencing format"
    },
    {
        id: 6,
        permanentEmployeeIcon: require("../../Assets/Careers/Freelancing_Opportunities/Researching_Ability.png"),
        para: "Good Researching Ability"
    },
    {
        id: 7,
        permanentEmployeeIcon: require("../../Assets/Careers/Freelancing_Opportunities/Subject_Matter.png"),
        para: "Subject Matter Expert"
    },
    {
        id: 8,
        permanentEmployeeIcon: require("../../Assets/Careers/Freelancing_Opportunities/TDS.png"),
        para: "TDS as per Government Rules"
    },
    {
        id: 9,
        permanentEmployeeIcon: require("../../Assets/Careers/Freelancing_Opportunities/Meet_Deadlines.png"),
        para: "Should be able to Meet Deadlines"
    },
    {
        id: 10,
        permanentEmployeeIcon: require("../../Assets/Careers/Freelancing_Opportunities/Pricing.png"),
        para: "Variable Pricing"
    },
    {
        id: 11,
        permanentEmployeeIcon: require("../../Assets/Careers/Freelancing_Opportunities/Net_Banking.png"),
        para: "Payment Through Net Banking"
    },
    {
        id: 12,
        permanentEmployeeIcon: require("../../Assets/Careers/Freelancing_Opportunities/Payment.png"),
        para: "Payment by 10th of Every Month"
    },
];

export default FreelancingOpportunities;