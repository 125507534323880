import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RDayImage1 from "../../../../../../Assets/Events/2022/Christmas-2022/Christmas_1.png";
import RDayImage2 from "../../../../../../Assets/Events/2022/Christmas-2022/Christmas_2.png";
import RDayImage3 from "../../../../../../Assets/Events/2022/Christmas-2022/Christmas_3.png";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const ChristmasDay2022 = () => {
  return (
    <>
    <section className="carousel_slider_section">
        <div className="container carouselSliderFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  // slideShadows: true,
                }}
                // pagination={{
                //     clickable: true,
                //     dynamicBullets: true,
                // }}
                loop={true}
                autoplay={{ delay: 3000 }}
                navigation={true}
                modules={[EffectCoverflow, Navigation, Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage1} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage2} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage3} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage1} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage2} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage3} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-md-12 blog-heading-and-para-div">
                    <h1>Christmas Celebration #2022</h1>
                    <p>'Tis the season for holiday cheer and celebration!' At Holistic learning, we know how to get into the festive spirit with various holiday activities.</p>
                    <p>Christmas is a great time for team building and morale as a company. Employees should feel a sense of joy and unity as they celebrate the holiday together. Adorning the office with festive decorations can help create a Christmas atmosphere and bring the team together. From a Christmas tree decorated with twinkling lights to a garland of holly and mistletoe, the office should transform into a winter wonderland.</p>
                    <p>First up, we deck the halls with boughs of holly, twinkling colorful balloons, and all the festive trimmings. The office transforms into a winter wonderland with a beautifully decorated tree. It's a sight to behold and gets everyone in the holiday mood.</p>
                    <p>We gather around the table on the big day to enjoy a delicious Christmas feast. The spread includes all the classic dishes we ordered from "Call me Chow," a veritable feast for a king (or queen)! With delicious panned Asian food, no holiday celebration is complete without a few festive beverages to wash it all down.</p>
                    <p>The real fun begins when it's time to hide the gifts. We employ the 'hide-and-seek' technique to add an element of surprise to proceedings. The others eagerly uncover the presents as they take turns hiding them. It is a great way to make office days more fun, as well as bring a bit of excitement to the whole office. The enthusiasm of guessing who your Secret Santa is might add an element of surprise and keeps everyone on their toes.</p>
                    <p>Someone has unwrapped the presents; it's time to let off a little steam with some friendly competition. We break out the UNO decks and prepare for a rousing game (or two or three...). It's the perfect way to let our hair down and has good old-fashioned fun with our colleagues.</p>
                    <p>All in all, Christmas celebration is a time for coming together, sharing in the holiday spirit, and creating lasting memories. It's a time to let our hair down and have some fun, and We're sure this holiday season will be as memorable as ever! We wouldn't have it any other way. Ho ho ho!</p>
                </div>
                <div className="col-md-6 blog-writer-div">
                    <p>Written By ~ Keerthana V</p>
                </div>
                <div className="col-md-6 blog-button-div">
                    <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default ChristmasDay2022;