
const DiwaliData23 = [
    {
        id: 1,
        imageSource: require("../../../Assets/Events/2023/Diwali_2023/1.webp")
    },
    {
        id: 2,
        imageSource: require("../../../Assets/Events/2023/Diwali_2023/2.webp")
    },
    {
        id: 3,
        imageSource: require("../../../Assets/Events/2023/Diwali_2023/3.webp")
    },
    {
        id: 4,
        imageSource: require("../../../Assets/Events/2023/Diwali_2023/4.webp")
    },
    {
        id: 5,
        imageSource: require("../../../Assets/Events/2023/Diwali_2023/5.webp")
    },
    {
        id: 6,
        imageSource: require("../../../Assets/Events/2023/Diwali_2023/6.webp")
    },
    {
        id: 7,
        imageSource: require("../../../Assets/Events/2023/Diwali_2023/7.webp")
    }
];

export default DiwaliData23;