import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RDayImage1 from "../../../../../../Assets/Events/2024/Republic_Day_2024/Republic_Day_24_1.webp";
import RDayImage2 from "../../../../../../Assets/Events/2024/Republic_Day_2024/Republic_Day_24_2.webp";
import RDayImage3 from "../../../../../../Assets/Events/2024/Republic_Day_2024/Republic_Day_24_3.webp";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const RepublicDay2024 = () => {
    return (
        <>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2,
                                    // slideShadows: true,
                                }}
                                // pagination={{
                                //     clickable: true,
                                //     dynamicBullets: true,
                                // }}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage1}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage2}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage3}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage1}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage2}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage3}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs-heading-and-para-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blog-heading-and-para-div">
                            <h1>Celebrating 75<sup>th</sup> Republic Day</h1>
                            <p>
                                Republic Day is a special day for all of us in India. It's a day when we remember how our country became a republic many years ago. Celebrating the <b style={{color: "rgba(187, 61, 39, 0.95)"}}>75th Republic Day</b> holds profound significance for India as it marks a milestone in its journey as a sovereign republic. This commemoration serves as a reminder of the enduring spirit of democracy, freedom, and the rule of law that has guided India through seven and a half decades of progress and challenges. It's a time to honor the sacrifices of the freedom fighters and visionaries who laid the foundation for a democratic India and to reflect on our achievements in various fields. Moreover, the 75th Republic Day symbolizes unity in diversity, showcasing the rich cultural heritage and the strength of our democratic values. It encourages a collective vision towards a more inclusive, equitable, and prosperous future for all citizens.
                            </p>
                            <p>
                                This year, at Holistic Learning, we celebrated this important day extraordinarily. We wanted everyone to feel the spirit of unity and enjoy the day together. All the Tribe members at <b style={{color: "rgba(187, 61, 39, 0.95)"}}>Holistic Learning</b> decided to wear ethnic clothes for the day. We dressed in beautiful, bright ethnic dresses representing the colorful and incredible India. Seeing everyone looking so cheerful and happy in their traditional outfits was amazing. It felt like a big, happy family coming together to celebrate. Wearing these clothes was our way of showing love for our country and its rich culture. Our office looked different on Republic Day. We decorated it with lots of tricolor balloons. These balloons were in saffron, white, and green - the colors of our Indian flag. It made our office look very festive and patriotic. The balloons were everywhere - near our desks, hallways, and the common areas. It felt like the spirit of Republic Day was all around us, reminding us of our country’s journey and the freedom we enjoy today.
                            </p>
                            <p>
                                One of the best parts of the day was playing games together. We had a special puzzle game about quotes from famous freedom fighters. It was fun to try and guess which leader said which inspiring words. This game made us think and learn more about the brave people fighting for our country's freedom. We also played team-building games, including non-verbal cues, to understand the posture. These games were designed to make us work together and solve problems as a team. 
                            </p>
                            <p>
                                It was a lot of fun and taught us how important it is to support and trust each other. Like in these games, we learned that working together strengthens us, just like it does for our country. What’s a celebration without good food? On Republic Day, we all enjoyed some delicious Indian delicacies and sweets. There were so many different types of food to try, each representing the diverse tastes of India. From savory snacks to sweet desserts, there was something for everyone to enjoy. Eating together was a great way to bond and share stories about our favorite foods and traditions. Celebrating Republic Day at Holistic Learning was a wonderful experience for all the tribe members. It was a day filled with fun, learning, and laughter. More than anything, it was a day that brought us all closer together. It reminded us of the beautiful diversity of our country and the strength we have when we stand united. We all went home feeling proud to be a part of this great nation and grateful for our shared freedom and unity. This Republic Day celebration at our office was exceptional. It showed us that it's not just about remembering the past but also celebrating our present and looking forward to our future together. 
                            </p>
                        </div>
                        <div className="col-md-6 blog-writer-div">
                            <p>Written By ~ Ankita D</p>
                        </div>
                        <div className="col-md-6 blog-button-div">
                            <NavLink to={"/events/more-events"} className="href">
                                <button>
                                    <span className="icon">
                                        <FaArrowLeft />
                                    </span>{" "}
                                    View All Events
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default RepublicDay2024;
