
const BirthdayEvents2022 = [
    {
        id: 1,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Apoorva.png"),
        birthdayName: "Apoorva",
        birthdayDate: <span>09<sup>th</sup> Jan</span>
    },
    {
        id: 2,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Surya.png"),
        birthdayName: "Surya P",
        birthdayDate: <span>30<sup>th</sup> Mar</span>
    },
    {
        id: 3,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Faisal.png"),
        birthdayName: "Faisal Khan",
        birthdayDate: <span>07<sup>th</sup> May</span>
    },
    {
        id: 4,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Deepak.png"),
        birthdayName: "Deepak B",
        birthdayDate: <span>14<sup>th</sup> May</span>
    },
    {
        id: 5,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Gowthami.png"),
        birthdayName: "Gowthami N",
        birthdayDate: <span>19<sup>th</sup> May</span>
    },
    {
        id: 6,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Punith.png"),
        birthdayName: "Punith Raj",
        birthdayDate: <span>28<sup>th</sup> May</span>
    },
    {
        id: 7,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Shruti.png"),
        birthdayName: "Shruti C",
        birthdayDate: <span>22<sup>nd</sup> June</span>
    },
    {
        id: 8,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Priyanka.png"),
        birthdayName: "Priyanka R",
        birthdayDate: <span>17<sup>th</sup> July</span>
    },
    {
        id: 9,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Tapojit.png"),
        birthdayName: "Tapojit",
        birthdayDate: <span>07<sup>th</sup> Sep</span>
    },
    {
        id: 10,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Renu.png"),
        birthdayName: "Renu S",
        birthdayDate: <span>12<sup>th</sup> Sep</span>
    },
    {
        id: 11,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Keerthana.png"),
        birthdayName: "Keerthana V",
        birthdayDate: <span>24<sup>th</sup> Sep</span>
    },
    {
        id: 12,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Arihant.png"),
        birthdayName: "Arihant B",
        birthdayDate: <span>06<sup>th</sup> Oct</span>
    },
    {
        id: 13,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Swagata.png"),
        birthdayName: "Swagata",
        birthdayDate: <span>10<sup>th</sup> Oct</span>
    },
    {
        id: 14,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Vishwas.png"),
        birthdayName: "Vishwas",
        birthdayDate: <span>26<sup>th</sup> Nov</span>
    },
];

export default BirthdayEvents2022;