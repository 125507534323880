import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RDayImage1 from "../../../../../../Assets/Events/2022/Holi-2022/4.1.png";
import RDayImage2 from "../../../../../../Assets/Events/2022/Holi-2022/4.2.png";
import RDayImage3 from "../../../../../../Assets/Events/2022/Holi-2022/4.3.png";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const Holi2022 = () => {
  return (
    <>
    <section className="carousel_slider_section">
        <div className="container carouselSliderFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  // slideShadows: true,
                }}
                // pagination={{
                //     clickable: true,
                //     dynamicBullets: true,
                // }}
                loop={true}
                autoplay={{ delay: 3000 }}
                navigation={true}
                modules={[EffectCoverflow, Navigation, Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage1} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage2} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage3} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage1} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage2} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiperSlide">
                  <div className="swiper-slide-img">
                    <img src={RDayImage3} alt="Avatar" className="nexus" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-md-12 blog-heading-and-para-div">
                    <h1>Holi #2022</h1>
                    <p>Holi, commonly known as the festival of spring or the festival of colors, is a famous event. It is also known as the festival of colors" since people can get together and forget about their problems and resentments on this day. Holi is a springtime festival that commemorates the entrance of the new season.</p>
                    <p>Holistic learning celebrated this event with a splash of colors. The atmosphere on 17 March 2022 was marked with fun and colors. It includes a variety of games, amusements, and refreshments. It was a joyous occasion. The holistic learning office was a mash-up of engrossed employees who sprinkled colors on everyone. As everyone took so many images, the gathering was full of togetherness and memories. The celebration of colors, Holi was a cultural melting pot that drove everyone to bend even farther. Everyone feels a sense of belonging and friendliness during the festival.</p>
                </div>
                <div className="col-md-6 blog-writer-div">
                    <p>Written By ~ Surya P</p>
                </div>
                <div className="col-md-6 blog-button-div">
                    <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Holi2022;