
const MainPageBannerImg = [
    {
        id: 1,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_22.webp")
    },
    {
        id: 2,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_21.webp")
    },
    {
        id: 3,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_20.webp")
    },
    {
        id: 4,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_19.webp")
    },
    {
        id: 5,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_18.webp")
    },
    {
        id: 6,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_17.webp")
    },
    {
        id: 7,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_16.webp")
    },
    {
        id: 8,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_15.png")
    },
    {
        id: 9,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_14.png")
    },
    {
        id: 10,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_13.png")
    },
    {
        id: 12,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_12.png")
    },
    {
        id: 13,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_11.png")
    },
    {
        id: 14,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_10.png")
    },
    {
        id: 15,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_9.png")
    },
    {
        id: 16,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_8.png")
    },
    {
        id: 17,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_7.png")
    },
    {
        id: 18,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_6.webp")
    },
    {
        id: 19,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_5.png")
    },
    {
        id: 20,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_4.png")
    },
    {
        id: 21,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_3.png")
    },
    {
        id: 22,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_2.png")
    },
    {
        id: 23,
        bannerImg: require("../../Assets/Events/Main_Page_Images/Events_Banner_Image_HL/banner_1.png")
    },
];

export default MainPageBannerImg;