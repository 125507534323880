
const ITRoomSetupCSRData23 = [
    {
        id: 1,
        imageSource: require("../../../Assets/Events/2023/CSR/IT_Room_Setup/1.webp")
    },
    {
        id: 2,
        imageSource: require("../../../Assets/Events/2023/CSR/IT_Room_Setup/2.webp")
    },
    {
        id: 3,
        imageSource: require("../../../Assets/Events/2023/CSR/IT_Room_Setup/3.webp")
    },
    {
        id: 4,
        imageSource: require("../../../Assets/Events/2023/CSR/IT_Room_Setup/4.webp")
    },
    {
        id: 5,
        imageSource: require("../../../Assets/Events/2023/CSR/IT_Room_Setup/5.webp")
    },
    {
        id: 6,
        imageSource: require("../../../Assets/Events/2023/CSR/IT_Room_Setup/6.webp")
    },
    {
        id: 7,
        imageSource: require("../../../Assets/Events/2023/CSR/IT_Room_Setup/7.webp")
    },
    {
        id: 8,
        imageSource: require("../../../Assets/Events/2023/CSR/IT_Room_Setup/8.webp")
    },
    {
        id: 9,
        imageSource: require("../../../Assets/Events/2023/CSR/IT_Room_Setup/9.webp")
    }
];

export default ITRoomSetupCSRData23;