import React from 'react';
import { Helmet } from 'react-helmet';
import ServiceLogo from "../../Assets/Products_and_Services/Logo.png";
import Product from "../../Assets/Products_and_Services/Product.webp";
import Service from "../../Assets/Products_and_Services/Services.webp";
import ProductLogo from "../../Assets/Products_and_Services/nexus_logo.png";
import "../CSS/ProductsAndServices.css";

const ProductsAndServices = () => {
  return (
    <>
      <Helmet>
        <title>Holistic Learning : Product & Services</title>
        {/* <meta
          name="description"
          content="We at Holistic Learning offer students help with their assignments, homework and online courses. We assure grade A or B or money back."
        /> */}
        <link rel="canonical" href="https://holisitclearning.com/products-and-services" />
      </Helmet>
      <section className="products-and-services-heading-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 products-and-services-heading-div">
              <h1>Our <span>SOLUTIONS</span></h1>
            </div>
          </div>
        </div>
      </section>
      <section className="products-heading-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 product-image-div">
              <img src={Product} alt="" className='img-fluid' />
            </div>
            <div className="col-md-12 product-content-div">
              <p><span><img src={ProductLogo} alt="" className='img-fluid' />                               chat app is a humble way to change the way we stay in touch with our friends and family. In a bid to enhance privacy and security, we created a new chatting platform. The most important feature of the app is the incognito group chat feature. As an admin, you can add anyone in an incognito group and the participants will never be able to find out who the other participants are. Not only that, the participants will not be able to talk to other participants on a one-to-one basis outside the group. The feature does allows many businesses to seamlessly add their clients and suppliers (two ends of the spectrum) and without the risk of revealing the identity of customer to the supplier and vice versa.</span></p>
              <a href="https://www.nexuschatapp.com/" className="href" target='_blank' rel='noreferrer noopener'><button>Visit website</button></a>
            </div>
          </div>
        </div>
      </section>
      <section className="products-heading-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 service-image-div">
              <img src={Service} alt="" className='img-fluid' />
            </div>
            <div className="col-md-12 service-content-div">
              <p><span><img src={ServiceLogo} alt="" className='img-fluid' /></span>                         took our journey forward - from academic writing to writing for corporates. We accumulated our learnings and ventured into blog writing, website content writing and product description writing. Be My Wordsmith was thus born. This website is dedicated to all other forms of writing be it blogs or product descriptions or website content writing. We understand that having an online presence is very important for survival in today’s world. However, a content that is relevant to your business and one that pushes the prospective customer to make a purchase is equally important. So while you are making a home on the internet, let us also make it comfortable for guests who are going to visit your home frequently.</p>
              <a href="https://www.bemywordsmith.com/" className="href" target='_blank' rel='noreferrer noopener'><button>Visit website</button></a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProductsAndServices;