
const Diwali22 = [
    {
        id: 1,
        imageSource: require("../../../Assets/Events/2022/Diwali-2022/Diwali-1.png")
    },
    {
        id: 2,
        imageSource: require("../../../Assets/Events/2022/Diwali-2022/Diwali-2.png")
    },
    {
        id: 3,
        imageSource: require("../../../Assets/Events/2022/Diwali-2022/Diwali-3.png")
    },
    {
        id: 4,
        imageSource: require("../../../Assets/Events/2022/Diwali-2022/Diwali-4.png")
    }
];

export default Diwali22;