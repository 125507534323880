const BirthdayEvents2023 = [
    {
        id: 1,
        birthdayImage: require("../../../Assets/Events/2023/Birthdays-2023/BDAY-Faisal.webp"),
        birthdayName: "Faisal Khan",
        birthdayDate: <span>07<sup>th</sup> May</span>
    },
    {
        id: 2,
        birthdayImage: require("../../../Assets/Events/2023/Birthdays-2023/BDAY-Deepak.webp"),
        birthdayName: "Deepak B",
        birthdayDate: <span>14<sup>th</sup> May</span>
    },
    {
        id: 3,
        birthdayImage: require("../../../Assets/Events/2023/Birthdays-2023/BDAY-Aparna.webp"),
        birthdayName: "Aparna P",
        birthdayDate: <span>18<sup>th</sup> May</span>
    },
    {
        id: 4,
        birthdayImage: require("../../../Assets/Events/2023/Birthdays-2023/BDAY-Gowthami.webp"),
        birthdayName: "Gowthami N",
        birthdayDate: <span>19<sup>th</sup> May</span>
    },
    {
        id: 5,
        birthdayImage: require("../../../Assets/Events/2023/Birthdays-2023/BDAY-Punith.webp"),
        birthdayName: "Punith Raj",
        birthdayDate: <span>28<sup>th</sup> May</span>
    },
    {
        id: 6,
        birthdayImage: require("../../../Assets/Events/2023/Birthdays-2023/BDAY-Riyah.webp"),
        birthdayName: "Riyah F",
        birthdayDate: <span>18<sup>th</sup> Aug</span>
    },
    {
        id: 7,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Renu.png"),
        birthdayName: "Renu S",
        birthdayDate: <span>12<sup>th</sup> Sep</span>
    },
    {
        id: 8,
        birthdayImage: require("../../../Assets/Events/2023/Birthdays-2023/BDAY-Keerthana.webp"),
        birthdayName: "Keerthana V",
        birthdayDate: <span>24<sup>th</sup> Sep</span>
    },
    {
        id: 9,
        birthdayImage: require("../../../Assets/Events/2023/Birthdays-2023/BDAY-Ipsita.webp"),
        birthdayName: "Ipsita P",
        birthdayDate: <span>27<sup>th</sup> Sep</span>
    },
    {
        id: 10,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Arihant.png"),
        birthdayName: "Arihant B",
        birthdayDate: <span>06<sup>th</sup> Oct</span>
    },
    {
        id: 11,
        birthdayImage: require("../../../Assets/Events/2023/Birthdays-2023/BDAY-Jaishree.webp"),
        birthdayName: "Jaishree D",
        birthdayDate: <span>30<sup>th</sup> Dec</span>
    },
];

export default BirthdayEvents2023;