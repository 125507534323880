import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import OfficeDayData22 from "../../../../../../Data/Events/2022/OfficeDayData22";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const OfficeDay2022 = () => {
  return (
    <>
      <section className="carousel_slider_section">
        <div className="container carouselSliderFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  // slideShadows: true,
                }}
                // pagination={{
                //     clickable: true,
                //     dynamicBullets: true,
                // }}
                loop={true}
                autoplay={{ delay: 3000 }}
                navigation={true}
                modules={[EffectCoverflow, Navigation, Autoplay]}
                className="mySwiper"
              >
                {OfficeDayData22.map((items) => {
                  return (
                    <SwiperSlide className="swiperSlide" key={items.id}>
                      <div className="swiper-slide-img">
                        <img
                          src={items.imageSource}
                          alt="Avatar"
                          className="nexus"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 blog-heading-and-para-div">
              <h1>Office Day Celebration #2022</h1>
              <p>
                Independence day/office day in Holistic Learning two in one
                hand, the event we enjoyed together in the office was memorable.
                It is a kind of family celebration of the Holistic family.
                Everyone is filled with joy. One special thing about this event
                is that one of our Holistic Learning co-founders, Arihant, had
                flown down once in a blue moon to Bangalore. Our co-founders
                foster the young minds to move with ideas to be independent in a
                unified manner.
              </p>
              <p>
                Tapojit conducted the Independence Day event; he took the event
                remarkably as we started our day with the National Anthem (Jana
                Gana Mana). We all went to Abhya ashram, a wonderful place where
                we contributed groceries to kids staying there.
              </p>
              <p>
                Our real celebration starts with a heart-melting video from
                Punith. The entire day was filled with fun, laughter, and games.
                The game begins with a killer killer, in that the police need to
                find the killer. Unlike in the movies, the gunshot was a blink
                of an eye! The killer needs to wink his eye at a particular
                person; he says I'm dead. We went to Domino's pizza; our picture
                speaks everything; we sat together, ate, chatted, and played
                with numbers. It is a kind of new experience for me.
              </p>
              <h2>The Game Continues</h2>
              <p>
                Mind games were like a word connection. I felt like it connected
                everyone. Have you guys seen the trending reels where celebs
                flip the bottle to get a score? Well, we played that, and none
                could get it right! :-p but I hit the winning spot!
              </p>
              <p>
                Bottle flipping games are all about when we flip the bottle; it
                should sit on the table without falling. In paper cup games, I
                felt like watching a police thief! We were separated into two
                teams, boys vs. girls. Each team should arrange all the cups
                like a pyramid and then pile the cups vertically line-by-line to
                the bottom. I hit the jackpot by beating the boy's team proud
                moment.
              </p>
              <p>
                Mind full game is like we took a glass full of water; it should
                pass one by one with closing our eyes. Somewhere cheated, that
                made it even more fun. Chinese whispers laughter more, and we
                form a circle. The person whispers one sentence to the ears of
                the next person, and so on, changing the entire sentence at the
                end.
              </p>
              <p>
                We all sat on the floor with UNO cards, chatting and playing
                happily. One of our founders came up with an idea like the
                person who throws the card 0 can change the cards with one who
                has one card, and then the person who throws the card 7 all
                needs to change their cards either clockwise or anticlockwise.
                We burst out with laughter. The explanation of this game goes
                lengthy. Think about how well we enjoyed it!
              </p>
              <p>
                We ended with our office game carmon Deepak sir, won the game. A
                day of laughter from the heart. I was overjoyed and overwhelmed.
                It is a day of cleansing all our minds and hearts. Everyone is
                growing backward; it's reminiscing our childhood memories.
              </p>
            </div>
            <div className="col-md-6 blog-writer-div">
              <p>Written By ~ Keerthana V</p>
            </div>
            <div className="col-md-6 blog-button-div">
              <NavLink to={"/events/more-events"} className="href">
                <button>
                  <span className="icon">
                    <FaArrowLeft />
                  </span>{" "}
                  View All Events
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OfficeDay2022;
