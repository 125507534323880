import React from 'react';
import { NavLink } from 'react-router-dom';
import WhoAreWe from "../../Assets/Landing_Page/About us page.gif";
import WhatWeDo from "../../Assets/Landing_Page/New entries.gif";
import Nexus from "../../Assets/Products_and_Services/Product.webp";
import BeMyWordsmith from "../../Assets/Products_and_Services/Services.webp";
import "../CSS/LandingPage.css";

const LandingPage = () => {
  return (
    <>
    <section className="who-are-we-section-start-here">
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-lg-6 who-are-we-image-div">
                    <img src={WhoAreWe} alt="" className='img-fluid' />
                </div>
                <div className="col-lg-6 who-are-we-heading-and-para-div">
                    <h2><span>WHO</span> Are We?</h2>
                    <p>We would like to introduce ourselves as passionate and enthusiastic writers. However, we can live with just being called ardent writers. At the end of day, who we are is known by what we write and how we write.</p>
                    <NavLink to={"/know-us"} className="href"><button>Read more</button></NavLink>
                </div>
            </div>
        </div>
    </section>
    <section className="what-we-do-section-start-here">
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-lg-6 what-we-do-heading-and-para-div">
                    <h2><span>WHAT</span> We Do?</h2>
                    <p>Writing, writing and more writing! That is what we do. At Holistic Learning, we write - be it academic content, a blog, a website content, technical aspects, creative elements or any other form. We write! Writing is in our DNA.</p>
                    <NavLink to={"/know-us"} className="hr"><button>Read more</button></NavLink>
                </div>
                <div className="col-lg-6 what-we-do-image-div">
                    <img src={WhatWeDo} alt="" className='img-fluid' />
                </div>
            </div>
        </div>
    </section>
    <section className="our-solutions-section-start-here">
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-md-12 our-solutions-heading-div">
                    <h2>Our <span>SOLUTIONS</span></h2>
                </div>
                <div className="col-md-6 our-solutions-product-div">
                    <img src={Nexus} alt="Nexus Chat App" className='img-fluid' />
                </div>
                <div className="col-md-6 our-solutions-service-div">
                    <img src={BeMyWordsmith} alt="Be My Wordsmith" className='img-fluid' />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default LandingPage;