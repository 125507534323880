import React from 'react';
import { NavLink } from 'react-router-dom';
import FooterLogo from "../../Assets/LOGO/Footer_New_Logo.png";
import Instagram from "../../Assets/LOGO/Instagram.png";
import LinkedIn from "../../Assets/LOGO/LinkedIn.png";
import "../CSS/Footer.css";

const Footer = () => {
  return (
    <>
    <footer>
        <section className="footer-section-of-hl">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="footer-logo-and-content">
                            <div className="footer-logo">
                                <NavLink to={"/"} className="href"><img src={FooterLogo} alt="Holistic Learning Bangalore" className='img-fluid' /></NavLink>
                            </div>
                            <div className="footer-content">
                                <p className="footer-para">At Holistic Learning, we write - be it academic content, a blog, a website content, technical aspects, creative elements or any other form.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 footer-nav-menu-and-social-icon">
                        {/* <div className="footer-nav-menu-and-social-icon"> */}
                        <div className="footer-nav-menu-main-div">
                            <div className="first-three-footer-menu">
                                <ul>
                                    <li><NavLink to={"/our-team"} className="href">Our Team</NavLink></li>
                                    <li><NavLink to={"/careers"} className="href">Careers</NavLink></li>
                                </ul>
                            </div>
                            <div className="last-three-footer-menu">
                                <ul>
                                    <li><NavLink to={"/products-and-services"} className="href">Produsts & Services</NavLink></li>
                                    <li><NavLink to={"/touchbase"} className="href">Touch Base</NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-social-media-icon-div">
                            <ul>
                                <li><a href="https://www.instagram.com/holisticlearning_/" className="href" target='_blank' rel="noopener noreferrer"><img src={Instagram} alt="Instagram" /></a></li>
                                <li><a href="https://in.linkedin.com/company/holistic-learning-in" target='_blank' rel="noopener noreferrer" className="href"><img src={LinkedIn} alt="LinkedIn" /></a></li>
                            </ul>
                        </div>
                        {/* </div> */}
                    </div>
                    <div className="col-md-12 footer-last-div-content">
                        <p>© Copyright 2023, All Rights Reserved by <span><NavLink to={"/"} className="href">HOLISTICLEARNING.COM</NavLink></span></p>
                    </div>
                </div>
            </div>
        </section>
    </footer>
    </>
  )
}

export default Footer;