import './App.css';

import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Footer from './Component/JS/Footer';
import MoveToTop from "./Component/JS/MoveToTop.jsx";
import Navbar from './Component/JS/Navbar';
import BackendDevelopers from './Pages/ComponentUnderPages/CurrentOpenings/JSX/BackendDevelopers.jsx';
import DigitalMarketingExecutives from './Pages/ComponentUnderPages/CurrentOpenings/JSX/DigitalMarketingExecutives.jsx';
import FrontendDevelopers from './Pages/ComponentUnderPages/CurrentOpenings/JSX/FrontendDevelopers.jsx';
import GeneralContentWriters from './Pages/ComponentUnderPages/CurrentOpenings/JSX/GeneralContentWriters';
import TechnicalContentWriters from './Pages/ComponentUnderPages/CurrentOpenings/JSX/TechnicalContentWriters';
import UIAndUXDesigners from './Pages/ComponentUnderPages/CurrentOpenings/JSX/UIAndUXDesigners';
import WebsiteContentWriters from './Pages/ComponentUnderPages/CurrentOpenings/JSX/WebsiteContentWriters.jsx';
import AbhayaAshrama2022 from './Pages/ComponentUnderPages/EventsComponent/Blogs/CSRBlogs/CSR2022Blogs/AbhayaAshrama2022.jsx';
import ITRoomSetup from './Pages/ComponentUnderPages/EventsComponent/Blogs/CSRBlogs/CSR2023Blogs/ITRoomSetup.jsx';
import Diwali2021 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2021Blogs/Diwali2021.jsx';
import EveningOuting2021 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2021Blogs/EveningOuting2021.jsx';
import Holi2021 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2021Blogs/Holi2021.jsx';
import IndependenceDay2021 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2021Blogs/IndependenceDay2021.jsx';
import BirthdayCelebrations2022 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2022Blogs/BirthdayCelebrations2022.jsx';
import ChristmasDay2022 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2022Blogs/ChristmasDay2022.jsx';
import Diwali2022 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2022Blogs/Diwali2022.jsx';
import Holi2022 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2022Blogs/Holi2022.jsx';
import KamatBugleRock2022 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2022Blogs/KamatBugleRock2022.jsx';
import KannadaDay2022 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2022Blogs/KannadaDay2022.jsx';
import NewYear2022 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2022Blogs/NewYear2022.jsx';
import OfficeDay2022 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2022Blogs/OfficeDay2022.jsx';
import RepublicDay2022 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2022Blogs/RepublicDay2022.jsx';
import WayanadTrip2022 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2022Blogs/WayanadTrip2022.jsx';
import WomensDay2022 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2022Blogs/WomensDay2022.jsx';
import AnniversaryDay2023 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2023Blogs/AnniversaryDay2023.jsx';
import BirthdayCelebrations2023 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2023Blogs/BirthdayCelebrations2023.jsx';
import Christmas2023 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2023Blogs/Christmas2023.jsx';
import Diwali2023 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2023Blogs/Diwali2023.jsx';
import Holi2023 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2023Blogs/Holi2023.jsx';
import IndependenceDay2023 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2023Blogs/IndependenceDay2023.jsx';
import KarnatakaDay2023 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2023Blogs/KarnatakaDay2023.jsx';
import OutingFor12thFailFilm from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2023Blogs/OutingFor12thFailFilm.jsx';
import RepublicDay2023 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2023Blogs/RepublicDay2023.jsx';
import BirthdayCelebrations2024 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2024Blogs/BirthdayCelebrations2024.jsx';
import RepublicDay2024 from './Pages/ComponentUnderPages/EventsComponent/Blogs/EventsBlogs/2024Blogs/RepublicDay2024.jsx';
import CSRInnerPages from './Pages/ComponentUnderPages/EventsComponent/CSRComp/CSRInnerPages.jsx';
import EventsInnerPages from './Pages/ComponentUnderPages/EventsComponent/EventsComp/EventsInnerPages.jsx';
import Careers from './Pages/JS/Careers';
import Events from './Pages/JS/Events';
import KnowUs from './Pages/JS/KnowUs';
import LandingPage from './Pages/JS/LandingPage';
import Musings from './Pages/JS/Musings.jsx';
import OurTeam from './Pages/JS/OurTeam';
import ProductsAndServices from './Pages/JS/ProductsAndServices';
import Touchbase from './Pages/JS/Touchbase';

function App() {
  return (
    <>
      <Router>
        <MoveToTop />
        <Helmet>
          <title>Holistic Learning : Unlock Your Writing Potential with Holistic Learning</title>
          <meta name="description" content="Welcome to Holistic Learning, your destination for content writing career opportunities in Bangalore." />
          <link rel="canonical" href="https://www.holisitclearning.com/" />
        </Helmet>
        <Navbar />
        <div>
          <Routes>
            <Route exact path={"/"} element={<LandingPage />} />
            <Route exact path={"/know-us"} element={<KnowUs />} />
            <Route exact path={"/events"} element={<Events />} />
            {/* Events Inner Pages Start Here */}
            <Route exact path={"/events/more-events"} element={<EventsInnerPages />} />
            <Route exact path={"/events/more-csr-events"} element={<CSRInnerPages />} />
            {/* Events Inner Pages End Here */}
            <Route exact path={"/our-team"} element={<OurTeam />} />
            <Route exact path={"/careers"} element={<Careers />} />
            {/* Current Openings Start Here */}
            <Route exact path={"/careers/general-content-writers"} element={<GeneralContentWriters />} />
            <Route exact path={"/careers/technical-content-writers"} element={<TechnicalContentWriters />} />
            <Route exact path={"/careers/website-content-writers"} element={<WebsiteContentWriters />} />
            <Route exact path={"/careers/digital-marketing-executives"} element={<DigitalMarketingExecutives />} />
            <Route exact path={"/careers/front-end-developers"} element={<FrontendDevelopers />} />
            <Route exact path={"/careers/back-end-developers"} element={<BackendDevelopers />} />
            <Route exact path={"/careers/ui-ux-designer"} element={<UIAndUXDesigners />} />
            {/* Current Openings End Here */}
            <Route exact path={"/products-and-services"} element={<ProductsAndServices />} />
            <Route exact path={"/musings"} element={<Musings />} />
            <Route exact path={"/touchbase"} element={<Touchbase />} />
            {/* Event Blogs Start Here */}
            {/* 2024 Blogs */}
            <Route exact path={"/republic-day-2024"} element={<RepublicDay2024 />} />
            <Route exact path={"/birthday-celebration-2024"} element={<BirthdayCelebrations2024 />} />
            {/* 2023 Blogs */}
            <Route exact path={"/republic-day-2023"} element={<RepublicDay2023 />} />
            <Route exact path={"/holi-2023"} element={<Holi2023 />} />
            <Route exact path={"/independence-day-2023"} element={<IndependenceDay2023 />} />
            <Route exact path={"/happy-10th-anniversary-day-2023"} element={<AnniversaryDay2023 />} />
            <Route exact path={"/happy-karnataka-day-2023"} element={<KarnatakaDay2023 />} />
            <Route exact path={"/diwali-2023"} element={<Diwali2023 />} />
            <Route exact path={"/clocking-out-for-reel-time"} element={<OutingFor12thFailFilm />} />
            <Route exact path={"/christmas-day-2023"} element={<Christmas2023 />} />
            <Route exact path={"/birthday-celebration-2023"} element={<BirthdayCelebrations2023 />} />
            {/* 2022 Blogs */}
            <Route exact path={"/new-year-2022"} element={<NewYear2022 />} />
            <Route exact path={"/republic-day-2022"} element={<RepublicDay2022 />} />
            <Route exact path={"/womens-day-2022"} element={<WomensDay2022 />} />
            <Route exact path={"/holi-2022"} element={<Holi2022 />} />
            <Route exact path={"/kamat-bugle-rock-2022"} element={<KamatBugleRock2022 />} />
            <Route exact path={"/office-day-2022"} element={<OfficeDay2022 />} />
            <Route exact path={"/diwali-2022"} element={<Diwali2022 />} />
            <Route exact path={"/kannada-rajyotsava-2022"} element={<KannadaDay2022 />} />
            <Route exact path={"/christmas-day-2022"} element={<ChristmasDay2022 />} />
            <Route exact path={"/wayanad-trip-2022"} element={<WayanadTrip2022 />} />
            <Route exact path={"/birthday-celebrations-2022"} element={<BirthdayCelebrations2022 />} />
            {/* 2021 Blogs */}
            <Route exact path={"/independence-day-2021"} element={<IndependenceDay2021 />} />
            <Route exact path={"/diwali-2021"} element={<Diwali2021 />} />
            <Route exact path={"/holi-2021"} element={<Holi2021 />} />
            <Route exact path={"/evening-outing-2021"} element={<EveningOuting2021 />} />
            {/* Event Blogs End Here */}
            {/* CSR Blogs Start Here */}
            {/* 2023 CSR Blogs */}
            <Route exact path={"/it-room-setup-2023"} element={<ITRoomSetup />} />
            {/* 2022 CSR Blogs */}
            <Route exact path={"/abhaya-ashrama-2022"} element={<AbhayaAshrama2022 />} />
            {/* CSR Blogs End Here */}
            <Route exact path={"*"} element={<LandingPage />} />
          </Routes>
        </div>
        <Footer />
      </Router>

    </>
  );
}

export default App;
