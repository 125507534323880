import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import RDayImage1 from "../../../../../../Assets/Events/2023/Kannada_Rajyotsava_2023/1.png";
import RDayImage2 from "../../../../../../Assets/Events/2023/Kannada_Rajyotsava_2023/2.png";
import RDayImage3 from "../../../../../../Assets/Events/2023/Kannada_Rajyotsava_2023/3.png";
import RDayImage4 from "../../../../../../Assets/Events/2023/Kannada_Rajyotsava_2023/4.png";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const KarnatakaDay2023 = () => {
    return (
        <>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2,
                                    // slideShadows: true,
                                }}
                                // pagination={{
                                //     clickable: true,
                                //     dynamicBullets: true,
                                // }}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage1}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage2}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage3}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage4}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage2}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiperSlide">
                                    <div className="swiper-slide-img">
                                        <img
                                            src={RDayImage3}
                                            alt="Avatar"
                                            className="nexus"
                                        />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs-heading-and-para-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blog-heading-and-para-div">
                            <h1>Kannada Rajyotsava #2023</h1>
                            <p>At Holistic Learning, we recently marked the vibrant occasion of Karnataka Rajyotsava, celebrating the foundation day of our beloved state. On the 31st of October, our office transformed into a kaleidoscope of colors and traditions, reflecting the rich tapestry of Karnataka's diverse culture.</p>
                            <p>The day commenced with a delightful potluck, where team members brought in a wide array of dishes representing their unique cultural backgrounds. Sheathed in vibrant ethnic wear, we gathered to share not only the delectable cuisines but also the stories and traditions that accompany them. The air was filled with laughter and fellowship as we sat together, forging stronger bonds over the shared joy of good food.</p>
                            <p>Adding a dash of creativity to the festivities, we hosted a meme contest that humorously explored aspects of office work culture, social issues, and Karnataka-related events. The winner, Gowthami, stole the show with her insightful and witty memes focusing on the nuances of our office culture. Selected through a spirited voting process, Gowthami's victory added an extra layer of fun to the celebrations.</p>
                            <p>This Karnataka Rajyotsava was more than an event; it was a celebration of unity in diversity, a moment where we cherished the unique flavors that each team member brings to our close-knit Holistic Learning family. As we look back on this day, we not only honor our roots but also strengthen the bonds that make our workplace a truly special community.</p>
                        </div>
                        <div className="col-md-6 blog-writer-div">
                            <p>Written By ~ Punith R</p>
                        </div>
                        <div className="col-md-6 blog-button-div">
                            <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default KarnatakaDay2023;