import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import DiwaliData21 from "../../../../../../Data/Events/2021/DiwaliData21";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const Diwali2021 = () => {
  return (
    <>
      <section className="carousel_slider_section">
        <div className="container carouselSliderFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  // slideShadows: true,
                }}
                // pagination={{
                //     clickable: true,
                //     dynamicBullets: true,
                // }}
                loop={true}
                autoplay={{ delay: 3000 }}
                navigation={true}
                modules={[EffectCoverflow, Navigation, Autoplay]}
                className="mySwiper"
              >
                {DiwaliData21.map((items) => {
                  return (
                    <SwiperSlide className="swiperSlide" key={items.id}>
                      <div className="swiper-slide-img">
                        <img
                          src={items.imageSource}
                          alt="Avatar"
                          className="nexus"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 blog-heading-and-para-div">
              <h1>Diwali</h1>
              <p>
                Diwali is an exciting festival for many of them including me,
                the festival of lights that originated in India which takes
                place every year, marking the starting of the Hindu New Year.
                Diwali tradition is celebrated by Hindus. It also celebrates
                many different religions across the globe not only in India,
                Diwali honors the Hindu goddess of health, wealth, Lakshmi and
                to start a new life with lamping to destroy all negativity to
                spread positivity. On Diwali lights, lamps and lanterns used to
                celebrate the occasions are believed to help the goddess find
                way into people's homes to bring prosperity for the year and
                also joy for the children. Crackers are usually an exciting big
                part of Diwali celebrations, one way to celebrate Diwali would
                be to decorate your house lights and lamps with flowers.
              </p>
              <p>
                Diwali is celebrated across the country. It varies by each and
                every region based on their culture and way of
                celebration.Diwali in our Holistic Learning we celebrate it as a
                very auspicious day with group activity, singing, dancing,
                sharing sweets, team lunch, lamping, fun activity, talks about
                new things to implement in life, and very much exciting surprise
                gifts Boat wireless Airpods we got from Deepak sir. This is how
                we enjoyed the homely kind of celebration at the office.
              </p>
            </div>
            <div className="col-md-6 blog-writer-div">
              <p>Written by ~ Meghashree M V</p>
            </div>
            <div className="col-md-6 blog-button-div">
              <NavLink to={"/events/more-events"} className="href">
                <button>
                  <span className="icon">
                    <FaArrowLeft />
                  </span>{" "}
                  View All Events
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Diwali2021;
