import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import KamatBugleRockData22 from "../../../../../../Data/Events/2022/KamatBugleRockData";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const KamatBugleRock2022 = () => {
  return (
    <>
      <section className="carousel_slider_section">
        <div className="container carouselSliderFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2,
                  // slideShadows: true,
                }}
                // pagination={{
                //     clickable: true,
                //     dynamicBullets: true,
                // }}
                loop={true}
                autoplay={{ delay: 3000 }}
                navigation={true}
                modules={[EffectCoverflow, Navigation, Autoplay]}
                className="mySwiper"
              >
                {KamatBugleRockData22.map((items) => {
                  return (
                    <SwiperSlide className="swiperSlide" key={items.id}>
                      <div className="swiper-slide-img">
                        <img
                          src={items.imageSource}
                          alt="Avatar"
                          className="nexus"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12 blog-heading-and-para-div">
              <h1>Kamat Bugle Rock Restaurant</h1>
              <p>
                When Arihant, one of the co-founders of Holistic Learning, had
                flown all the way down to Bangalore, he wanted to experience the
                local touch of food, something authentic and out of the ordinary
                like idli's or dosa's; that's when we rode to Kamat Bugle Rock
                which was a traditional ride to Karnataka's own style of South
                Indian cuisine, where rice plays a secondary role to the
                platter, while veggies like cucumber, lettuce, okra, and plenty
                more salads thrive on cleansing to your palate.
              </p>
              <p>
                We were a pack of 13 together, with shiny banana leaves
                sprinkled with water wanting to be plated with steaming hot
                food; when the roti's and other salads flew in, the lunch could
                be described as the day when roti's battled with punchy lentil
                curry and scopes of butter to top it off. We even had a roti
                eating contest that just stayed in words while people were
                sighing away to take part in it.
              </p>
              <p>
                One curious person was Arihant, who kept the entire team active
                by questioning how each ingredient went together in the north of
                Karnataka and how every dish needed to be eaten; people from the
                home state even taught him how to drink a cup of buttery curd;
                it was a share of the years-old tradition and diversity felt
                like a graceful part of the day.
              </p>
            </div>
            <div className="col-md-6 blog-writer-div">
              <p>Written By ~ Punith R</p>
            </div>
            <div className="col-md-6 blog-button-div">
              <NavLink to={"/events/more-events"} className="href">
                <button>
                  <span className="icon">
                    <FaArrowLeft />
                  </span>{" "}
                  View All Events
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default KamatBugleRock2022;
