import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import BirthdayEvents2024 from '../../../../../../Data/Events/2024/BirthdayEvents2024';
import "../../CSSBlogs/MainBlogsCSSFile.css";

const BirthdayCelebrations2024 = () => {
  return (
    <>
    <section className="blogs-heading-and-para-section-start-here">
        <div className="container-fluid">
            <div className="row justify-flex-start">
                <div className="col-md-12 blog-heading-and-para-div">
                    <h1>BIRTHDAYS #2024</h1>
                </div>
                {
                    BirthdayEvents2024.map((items) => {
                        return(
                            <div className="col-md-4 blog-yearly-birthday-celebration-div" key={items.id}>
                                <div className="blog-yearly-birthday-celebration-first-inner-div">
                                    <img src={items.birthdayImage} alt="Birthday Celebrations - Holistic Learning" className='img-fluid' />
                                    <h4>{items.birthdayName}</h4>
                                     <p>{items.birthdayDate}</p>
                                </div>
                            </div>
                        );
                    })
                }
                <div className="col-md-12 blog-button-div">
                    <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default BirthdayCelebrations2024;