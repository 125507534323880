import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ChristmasData23 from '../../../../../../Data/Events/2023/ChristmasData23';
import "../../CSSBlogs/MainBlogsCSSFile.css";

const Christmas2023 = () => {
    return (
        <>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2,
                                    // slideShadows: true,
                                }}
                                // pagination={{
                                //     clickable: true,
                                //     dynamicBullets: true,
                                // }}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                {
                                    ChristmasData23.map((items) => {
                                        return (
                                            <SwiperSlide className="swiperSlide" key={items.id}>
                                                <div className="swiper-slide-img">
                                                    <img src={items.imageSource} alt="Avatar" className='nexus' />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs-heading-and-para-section-start-here blogs-heading-and-para-section-for-banner-class">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blog-heading-and-para-div">
                            <h1 className='christmas-heading'>CHRISTMAS AT HOLISTIC LEARNING</h1>
                            <p>Hello, fellow Holistic Learning tribe members! As one of the coordinators of our recent Christmas celebration, I am thrilled to share with you the magic and joy that filled our office on that festive day.</p>
                            <p>Working alongside my wonderful co-coordinator, Khushali, we embarked on a journey to create a memorable Christmas experience for our Holistic Learning family. With creativity and dedication, we meticulously planned each detail to ensure that the day would be filled with laughter, surprises, and heartfelt moments.</p>
                            <p>Our very own Keerthana stepped into the role of Santa Claus, bringing smiles to everyone's faces with her infectious energy and warmth. Before her grand entrance, we treated our colleagues to a small introduction, adding more anticipation to the festivities.</p>
                            <p>The spirit of giving was alive Deepak, our thoughtful manager, played a crucial role in spreading holiday cheer by placing beautifully wrapped gifts on each desk. The sight of these surprises brought smiles to everyone's faces, igniting a sense of excitement and wonder. But the excitement didn't stop there – the real magic began with our Secret Santa activity. Each member of our team had been assigned a Secret Santa, and the office buzzed with anticipation as we embarked on a delightful treasure hunt to discover our hidden gifts. The creativity and thoughtfulness behind each present were truly heartwarming, highlighting the spirit of giving that defines the holiday season. In the true spirit of gratitude, we also took the time to shower Deepak with individual gifts, expressing our gratitude for his hard work and kindness throughout the year. His genuine surprise and gratitude served as a reminder of the importance of acknowledging and celebrating the contributions of others and reinforced our team's friendship bonds.</p>
                            <p>Of course, a celebration would only be complete with capturing memories to cherish forever. Everyone eagerly lined up to take photos with Santa and each other at the selfie booth, immortalizing the joyous moments we shared.</p>
                            <p>After indulging in a delicious lunch, we reconvened for an afternoon of shared laughter and music. With his guitar in hand, Sayan led us in a delightful jamming session that had us all singing along in harmony.</p>
                            <p>As the day drew to a close, we couldn't help but feel grateful for the opportunity to come together and celebrate as a team. The Christmas celebration at Holistic Learning was a testament to the power of community and connection, filling us with warmth and happiness as we prepare to welcome the new year ahead. Here's to many more joyful moments together!</p>
                        </div>
                        <div className="col-md-6 blog-writer-div">
                            <p>Written By ~ Ipsita P</p>
                        </div>
                        <div className="col-md-6 blog-button-div">
                            <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Christmas2023;