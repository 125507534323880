import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import DMExecutive from "../../../../Assets/Careers/Openings/Digital_Marketing_Executives.gif";
import "../CSS/CurrentOpenings.css";

const DigitalMarketingExecutives = () => {
    const [changeClassName, setChangeClassName] = useState(1);
    const handleShowDiv = (e) => {
        setChangeClassName(e);
    }
    return (
        <>
            <Helmet>
                <title>Holistic Learning : Digital Marketing Executive</title>
                <meta name="description" content="Welcome to Holistic Learning, your destination for content writing career opportunities in Bangalore." />
                <link rel="canonical" href="https://holisitclearning.com/careers/digital-marketing-executives" />
            </Helmet>
            <section className="current-openings-first-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-6 current-openings-image-div">
                            <img src={DMExecutive} alt="" className='img-fluid' />
                        </div>
                        <div className="col-md-6 current-openings-heading-and-para-div">
                            <div className="current-openings-heading-and-para-first-inner-div">
                                <h1>DIGITAL MARKETING EXECUTIVES</h1>
                                <p>Digital Marketing Executive will be responsible in plan and execute all digital marketing, including SEO/SEM, marketing database, email, social media and display advertising campaigns. You should be highly creative in identifying target audiences and devising digital campaigns that engage, inform and motivate.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="current-openings-job-resume-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className={changeClassName === 1 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(1)}>
                            <h4>RESPONSIBILITY</h4>
                            {/* <h4 className='responsibility'>RESPONSIBILITY</h4> */}
                            {/* <h4 className='skills'>SKILLS REQUIRED</h4> */}
                            {/* <h4 className='qualifications'>QUALIFICATIONS</h4> */}
                        </div>
                        <div className={changeClassName === 2 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(2)}>
                            <h4>SKILLS REQUIRED</h4>
                        </div>
                        <div className={changeClassName === 3 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(3)}>
                            <h4>QUALIFICATIONS</h4>
                        </div>
                        {
                            changeClassName === 1 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-responsibility" id='reponsibility'>
                                        <ul>
                                            <li>Expertise in Extensive Keyword Research, On-page and Off-page Optimization.</li>
                                            <li>Optimize the site to ensure maximum rankings for each keyword targeted.</li>
                                            <li>Knowledge in Potent Competitor Analysis, New age link building, User flow analysis.</li>
                                            <li>Conceptualizes, produces and inputs content for the websites, videos and social media.</li>
                                            <li>Knowledge in creating/optimizing landing pages.</li>
                                            <li>Knowledge in link building.</li>
                                            <li>Knowledge in Google Analytics, Google/Bing, Webmaster tools.</li>
                                            <li>Social Media marketing, and Create Backlinks.</li>
                                            <li>Data analysis skills and fluency with performance metrics and ROI analysis.</li>
                                            <li>Directory & Blog Submission, Blog Commenting and Blog Writing.</li>
                                            <li>Graphic Designing with creative thinking.</li>
                                            <li>Ability to work and think independently while taking ownership of projects.</li>
                                            <li>Strong general computer skills and knowledge of Microsoft Office.</li>
                                        </ul>
                                        <div className="requirements">
                                            <h5>REQUIREMENTS</h5>
                                            <ul>
                                                <li>Knowledge in Google Adwords to run an ad campaign.</li>
                                                <li>Knowledge in Facebook Ads, LinkedIn, Twitter.</li>
                                                <li>Knowledge of SEO tools like MOZ.</li>
                                                <li>Knowledge in Google penalty recovery.</li>
                                                <li>Basic knowledge of CMS like Word press, Joomla, etc.</li>
                                                <li>Understanding of Web architecture and basic HTML, CSS and code structure.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </> : ""
                        }
                        {
                            changeClassName === 2 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-skills-required" id='skills-required'>
                                        <ul>
                                            <li>Creative Thinking and Problem Solving</li>
                                            <li>Communication and Interpersonal Skills</li>
                                            <li>Basic Design</li>
                                            <li>Social Media Marketing</li>
                                            <li>Video Marketing</li>
                                            <li>Email Marketing</li>
                                            <li>Google Analytics</li>
                                            <li>Blog Writing</li>
                                            <li>Adaptability and Continues Learning</li>
                                            <li>Digital Marketing Tools</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                        {
                            changeClassName === 3 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-qualifications" id='qualifications'>
                                        <ul>
                                            <li>Bachelor's</li>
                                            <li>Master's</li>
                                            <li>PhD</li>
                                            <li>Other Professional Degree</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                    </div>
                </div>
            </section>
            <section className="current-openings-button-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 current-openings-button-div">
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSex4wQ3i0jWQchIT0k8I94rjy6Cxs0AqLS0tabRVVrMYH-ejw/viewform" className="href" target='_blank' rel='noopener noreferrer'><button className="join-the-tribe">Join The Tribe</button></a>
                            <NavLink to={"/careers"} className="href"><button className="other-openings"><span className='icon'><FaArrowLeft /></span> Other Openings</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DigitalMarketingExecutives;