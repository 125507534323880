import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import MainEventPage from "../../Assets/Events/Events_Main_Page.gif";
import MainPageBannerImg from "../../Data/Events/MainPageBannerImg";
import MainPageCSRBannerImg from "../../Data/Events/MainPageCSRBannerImg";
import "../CSS/Events.css";

const Events = () => {
  const [addClassName, setAddClassName] = useState(1);
  const FirstBannerrImage = Math.floor(
    Math.random() * MainPageBannerImg.length
  );
  const SecondBannerrImage = Math.floor(
    Math.random() * MainPageBannerImg.length
  );
  const ThirdBannerrImage = Math.floor(
    Math.random() * MainPageBannerImg.length
  );
  const FourthBannerrImage = Math.floor(
    Math.random() * MainPageBannerImg.length
  );
  const FifthBannerrImage = Math.floor(
    Math.random() * MainPageCSRBannerImg.length
  );
  const SixthBannerrImage = Math.floor(
    Math.random() * MainPageCSRBannerImg.length
  );
  const SeventhBannerrImage = Math.floor(
    Math.random() * MainPageCSRBannerImg.length
  );
  const EighthBannerrImage = Math.floor(
    Math.random() * MainPageCSRBannerImg.length
  );
  const handleChangeClassName = (e) => {
    setAddClassName(e);
  };
  return (
    <>
      <Helmet>
        <title>Holistic Learning : Events</title>
        {/* <meta
          name="description"
          content="Welcome to Holistic Learning, your destination for content writing career opportunities in Bangalore."
        /> */}
        <link rel="canonical" href="https://holisitclearning.com/events" />
      </Helmet>
      <section className="events-page-first-section-start-here">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6 events-page-image-div">
              <img src={MainEventPage} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 events-page-heading-and-para-div">
              <h2>EVENTS</h2>
              <p>
              All work and no play made Jack a dull boy and so at Holistic Learning, the tribe ensures that no one ever has a single dull moment. So while we work smart and hard, we celebrate office events even harder. While carom, UNO, Sequence and other games are played almost daily, certain events are celebrated with more fervor. Below is a glimpse of the same.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="events-button-and-image-section-start-here">
        <div className="container-fluid events-button-and-image-first-inner-div-section-start-here">
          <div className="row justify-content-center">
            <div className="col-md-3 col-sm-3 col-3 events-button-div">
              <h4
                className={
                  addClassName === 1 ? `events` : `without-click-events`
                }
                onClick={() => handleChangeClassName(1)}
              >
                EVENTS
              </h4>
              <h4
                className={addClassName === 2 ? `csr` : `without-click-csr`}
                onClick={() => handleChangeClassName(2)}
              >
                CSR
              </h4>
            </div>
            <div className="col-md-9 col-sm-9 col-9 events-image-div">
              {addClassName === 1 ? (
                <>
                  <div className="events-first-two-images-div">
                    <NavLink to={"/events/more-events"} className="href">
                      <img
                        src={MainPageBannerImg[FirstBannerrImage].bannerImg}
                        alt=""
                        className="img-fluid top-banner-images"
                      />
                    </NavLink>
                    <NavLink to={"/events/more-events"} className="href">
                      <img
                        src={MainPageBannerImg[SecondBannerrImage].bannerImg}
                        alt=""
                        className="img-fluid top-banner-images hide-banner-images"
                      />
                    </NavLink>
                  </div>
                  <div className="events-third-fourth-images-div">
                    <NavLink to={"/events/more-events"} className="href">
                      <img
                        src={MainPageBannerImg[ThirdBannerrImage].bannerImg}
                        alt=""
                        className="img-fluid bottom-banner-images"
                      />
                    </NavLink>
                    <NavLink to={"/events/more-events"} className="href">
                      <img
                        src={MainPageBannerImg[FourthBannerrImage].bannerImg}
                        alt=""
                        className="img-fluid bottom-banner-images hide-banner-images"
                      />
                    </NavLink>
                  </div>
                </>
              ) : (
                ""
              )}
              {addClassName === 2 ? (
                <>
                  <div className="events-first-two-images-div">
                    <NavLink to={"/events/more-csr-events"} className="href">
                      <img
                        src={
                          MainPageCSRBannerImg[FifthBannerrImage].CSRBannerImg
                        }
                        alt=""
                        className="img-fluid top-banner-images"
                      />
                    </NavLink>
                    <NavLink to={"/events/more-csr-events"} className="href">
                      <img
                        src={
                          MainPageCSRBannerImg[SixthBannerrImage].CSRBannerImg
                        }
                        alt=""
                        className="img-fluid top-banner-images hide-banner-images"
                      />
                    </NavLink>
                  </div>
                  <div className="events-third-fourth-images-div">
                    <NavLink to={"/events/more-csr-events"} className="href">
                      <img
                        src={
                          MainPageCSRBannerImg[SeventhBannerrImage].CSRBannerImg
                        }
                        alt=""
                        className="img-fluid bottom-banner-images"
                      />
                    </NavLink>
                    <NavLink to={"/events/more-csr-events"} className="href">
                      <img
                        src={
                          MainPageCSRBannerImg[EighthBannerrImage].CSRBannerImg
                        }
                        alt=""
                        className="img-fluid bottom-banner-images hide-banner-images"
                      />
                    </NavLink>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Events;
