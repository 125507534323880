import { NavLink } from "react-router-dom";
import BannerImg1 from "../../../Assets/Events/2022/Banner_Images/CSR_banner_2.webp";

const CSR2022Banner = [
    {
        id: 1,
        bannerImage: <NavLink to={"/abhaya-ashrama-2022"} className="href"><img src={BannerImg1} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/abhaya-ashrama-2022"} className="href">Solidarity with Children at Abhya Ashrama</NavLink></h4>
    }
];

export default CSR2022Banner;