import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import UIUXDesigners from "../../../../Assets/Careers/Openings/UI-UX_Designer.gif";
import "../CSS/CurrentOpenings.css";

const UIAndUXDesigners = () => {
    const [changeClassName, setChangeClassName] = useState(1);
    const handleShowDiv = (e) => {
        setChangeClassName(e);
    }
    // useCallback(() => {
    //     setChangeClassName()
    // }, [])
    return (
        <>
            <Helmet>
                <title>Holistic Learning : UI / UX Designer</title>
                <meta name="description" content="Welcome to Holistic Learning, your destination for content writing career opportunities in Bangalore." />
                <link rel="canonical" href="https://holisitclearning.com/careers/ui-ux-designer" />
            </Helmet>
            <section className="current-openings-first-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-6 current-openings-image-div">
                            <img src={UIUXDesigners} alt="" className='img-fluid' />
                        </div>
                        <div className="col-md-6 current-openings-heading-and-para-div">
                            <div className="current-openings-heading-and-para-first-inner-div">
                                <h1>UI / UX DESIGNERS</h1>
                                <p>UI / UX Designer responsibilities include gathering user requirements, designing graphic elements and building navigation components. you'll create both functional and appealing features that address our clients' needs and help us grow our customer base.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="current-openings-job-resume-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className={changeClassName === 1 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(1)}>
                            <h4>RESPONSIBILITY</h4>
                            {/* <h4 className='responsibility'>RESPONSIBILITY</h4> */}
                            {/* <h4 className='skills'>SKILLS REQUIRED</h4> */}
                            {/* <h4 className='qualifications'>QUALIFICATIONS</h4> */}
                        </div>
                        <div className={changeClassName === 2 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(2)}>
                            <h4>SKILLS REQUIRED</h4>
                        </div>
                        <div className={changeClassName === 3 ? `col-md-4 col-sm-4 col-6 current-openings-resume-after-click-div` : `col-md-4 col-sm-4 col-6 current-openings-resume-title-div`} onClick={() => handleShowDiv(3)}>
                            <h4>QUALIFICATIONS</h4>
                        </div>
                        {
                            changeClassName === 1 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-responsibility" id='reponsibility'>
                                        <ul>
                                            <li>Gather and evaluate user requirements in collaboration with product managers and engineers.</li>
                                            <li>Identify and troubleshoot UX problems.</li>
                                            <li>Build page navigation buttons and search fields.</li>
                                            <li>Develop UI mock-ups and prototypes that clearly illustrate how sites function and look like.</li>
                                            <li>Illustrate design ideas using storyboards, process flows and sitemaps.</li>
                                            <li>Design graphic user interface elements, like menus, tabs and widgets.</li>
                                            <li>Conduct layout adjustments based on user feedback.</li>
                                            <li>Adhere to style standards on fonts, colours and images.</li>
                                            <li>Prepare and present rough drafts to internal teams and key stakeholders.</li>
                                            <li>Identify and troubleshoot UX problems.</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                        {
                            changeClassName === 2 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-skills-required" id='skills-required'>
                                        <ul>
                                            <li>Knowledge of wireframe tools (e.g., Wireframe.cc and In Vision)</li>
                                            <li>Up-to-date knowledge of design software like Adobe Illustrator and Photoshop</li>
                                            <li>Team spirit; strong communication skills to collaborate with various stakeholders</li>
                                            <li>Good time-management skills</li>
                                            <li>Portfolio of design projects</li>
                                            <li>Update with the latest UI trends, techniques, and technologies</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                        {
                            changeClassName === 3 ?
                                <>
                                    <div className="col-md-12 col-sm-12 col-12 resume-qualifications" id='qualifications'>
                                        <ul>
                                            <li>Bachelor's</li>
                                            <li>Master's</li>
                                            <li>PhD</li>
                                            <li>Other Professional Degree</li>
                                        </ul>
                                    </div>
                                </> : ""
                        }
                    </div>
                </div>
            </section>
            <section className="current-openings-button-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 current-openings-button-div">
                            <NavLink to={"/touchbase"} className="href"><button className="join-the-tribe">Join The Tribe</button></NavLink>
                            <NavLink to={"/careers"} className="href"><button className="other-openings"><span className='icon'><FaArrowLeft /></span> Other Openings</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default UIAndUXDesigners;